import clientApi from "./axiosconfig";

export async function getSettings(obj) { 
  const response = await clientApi.post("api/v1/options/get", obj.queryKey[0]);
  return response;
}

export async function updateSettings(obj) {  
  const response = await clientApi.post("api/v1/options/update", obj); 
  return response;
}
