import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getVersions } from "../../../api/version";
import CustomSkeleton from "../../../components/loading/CustomSkeleton";

export default function VersionsStatistics() {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["versions"],
    queryFn: getVersions,
  });

  if (isPending) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const reversedData = [...data?.data].reverse();

  const versions = reversedData || [];

  return (
    <div
      className="overflow-hidden rounded-lg bg-white   shadow sm:p-6"
      style={{ padding: "1rem", margin: "0.5rem" }}
    >
      <p
        className="truncate text-sm block font-medium text-gray-500"
        style={{ margin: 0, fontSize: "x-large" }}
      >
        {versions.length}
      </p>

      <p className="truncate text-sm block text-gray-500" style={{ margin: 0 }}>
        Versions
      </p>
    </div>
  );
}
