import React from 'react';
import './userlist.scss';
import { useQuery } from '@tanstack/react-query';

import { getUsers } from '../../api/user';
import EditUser from './components/EditUser';
import CreateUser from './components/CreateUser';
import DeleteUser from './components/DeleteUser';
import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['userslist'],
		queryFn: getUsers,
	});

	const users = data?.data || [];

	const renderActions = (rowData) => (
		<div className='flex space-x-2 justify-end'>
			<EditUser data={rowData} />

			<DeleteUser data={rowData} />
		</div>
	);

	return (
		<React.Fragment>
			<div id='userslist' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Users</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'>
							<CreateUser />
						</div>
					</div>
				</div>
				<div className=''>
					{/* {isPending && (
						<div className='flex justify-center items-center h-full'>
							<CustomSkeleton />
						</div>
					)} */}
					{isError && (
						<div className='text-red-500'>
							{error.message || 'An error occurred while fetching data'}
						</div>
					)}
					{users && (
						<>
							<div className='hidden lg:block'>
								<DataGrid
									showBorders={true}
									showColumnLines
									className='wide-card'
									dataSource={users}
									allowColumnResizing={true}
								>
									<Column
										dataField='name'
										caption='Name'
										dataType='string'
										cellRender={({ data }) => {
											return (
												<div>
													{data.first_name} {data.last_name}
												</div>
											);
										}}
									/>
									<Column
										dataField='email'
										caption='Email'
										dataType='string'
										minWidth={300}
									/>
									<Column
										dataField='role'
										caption='Role'
										dataType='string'
										minWidth={300}
									/>

									<Column
										dataField='created_at'
										caption='Created At'
										dataType='date'
										width={100}
									/>
									<Column
										caption='Actions'
										cellRender={({ data }) => renderActions(data)}
										width={200}
									/>

									<Paging defaultPageSize={15} />
									<Pager showPageSizeSelector={true} showInfo={true} />
								</DataGrid>
							</div>

							<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
								{users.map((item, index) => {
									return (
										<div
											className={`flex  px-4 py-2 justify-between ${
												index % 2 === 1 && 'bg-[#e5e5e557]'
											} `}
											style={{ borderTop: '1px solid #e5e5e5' }}
										>
											<div className='flex flex-col'>
												<span>
													{' '}
													{item.first_name} {item.last_name}
												</span>
												<span>{item.email}</span>
												<span>{item.role}</span>
												{moment(item.created_at).format(
													'DD/MM/YYYY'
												)}
											</div>
											<div>{renderActions(item)}</div>
										</div>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};
