import { List } from "devextreme-react";
import React, { useCallback, useEffect, useState } from "react";
import { getTickets } from "../../../api/ticket";
import { useQuery } from "@tanstack/react-query";
import { Status } from "../constants/constants";
import moment from "moment";
import Ticket from "./Ticket";
import SubmitTicketForm from "./SubmitTicketForm";
import ArrayStore from "devextreme/data/array_store";
import CustomSkeleton from "../../../components/loading/CustomSkeleton";
import { useLocation, useNavigate } from "react-router-dom";

const TicketList = (props) => {
  const {
    filterStatus,
    isNewTicket,
    setIsNewTicket,
    setDataStats,
    isMobileTicket,
  } = props;

  const navigate = useNavigate();
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Extract the id from the query parameters

  const listAttrs = { class: "list" };
  const renderListItem = (ticket) => {
    const pastDate = moment(ticket.created_at);
    const isNew = ticket.comments.length === 0;
    // Calculate the difference from now and format it as a relative time string
    const timeFromNow = pastDate.fromNow();

    return (
      <div
        className={`p-4   ${
          ticket?.id === currentTicket?.id ? "bg-blue-200" : ""
        }`}
        style={
          isNew
            ? {
                borderLeft: "4px solid #1d73e3",
              }
            : {
                borderLeft: "4px solid #fef2f2",
              }
        }
      >
        <div className="min-w-0">
          <div className="flex items-start gap-x-3">
            <p className="text-sm font-semibold leading-6  m-0  text-gray-900 w-full whitespace-pre-line">
              {ticket.title}
            </p>
            <Status status={ticket.status === "0" ? "Open" : "Closed"} />
          </div>
          <p className="text-sm   m-0  text-gray-600 w-full whitespace-pre-line">
            {ticket.module_path}
          </p>

          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <span>[{ticket?.id}]</span>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <p className="whitespace-nowrap m-0 ">
              <time dateTime={timeFromNow}>{timeFromNow}</time>
            </p>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <p className="truncate m-0 ">Issued by {ticket.issued_by}</p>
          </div>
        </div>
      </div>
    );
  };

  const [currentTicket, setCurrentTicket] = useState();

  const handleListSelectionChange = useCallback(
    (e) => {
      setIsNewTicket(false);
      const ticket = e.addedItems[0];
      if (ticket) navigate(`?id=${ticket.id}`);

      setCurrentTicket(ticket);
    },
    [setCurrentTicket, setIsNewTicket, navigate]
  );

  const { isPending, isError, data } = useQuery({
    queryKey: ["status"],
    queryFn: getTickets,
  });

  useEffect(() => {
    if (data?.data) {
      if (id) {
        const foundTicket = data.data.find((ticket) => ticket.id === id);
        if (foundTicket) {
          setCurrentTicket(foundTicket);
        }
      } else if (!currentTicket) {
        const reversedData = [...data.data].reverse();
        setCurrentTicket(reversedData[0]);
      }

      setDataStats({
        open: data.data.filter((e) => e.status === "0").length || 0,
        closed: data.data.filter((e) => e.status === "1").length || 0,
      });
    }
  }, [data, setDataStats, currentTicket, id]);

  useEffect(() => {}, []);
  if (isPending) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return (
      <div className="flex items-center h-full justify-center ">
        <div className="flex flex-col items-center justify-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <span>No data to dsiplay</span>
        </div>
      </div>
    );
  }

  // Reverse the entire dataset first
  const reversedData = [...data.data].reverse();

  // Then apply filtering
  const filteredAndReversedData =
    filterStatus != null
      ? reversedData.filter((e) => e.status === `${filterStatus}`)
      : reversedData;

  const dataSourceOptions = {
    store: new ArrayStore({
      data: filteredAndReversedData,
      key: "id",
    }),
    searchExpr: ["title", "description", "id"],
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3">
      <div
        className={`h-[84vh] overflow-auto lg:flex  ${
          isMobileTicket ? "hidden" : "flex"
        } `}
      >
        <List
          selectionMode="single"
          dataSource={dataSourceOptions}
          //   grouped={true}
          searchEnabled={true}
          onSelectionChanged={handleListSelectionChange}
          elementAttr={listAttrs}
          itemRender={renderListItem}
          pageLoadMode="scrollBottom"
        />
      </div>
      <div
        className={` lg:flex col-span-2 h-[84vh] overflow-auto ${
          isMobileTicket ? "" : "hidden"
        }`}
      >
        {isNewTicket ? (
          <SubmitTicketForm
            selectedModuleId={11}
            setIsNewTicket={setIsNewTicket}
            setCurrentTicket={setCurrentTicket}
          />
        ) : (
          <Ticket
            ticket={currentTicket}
            setCurrentTicket={setCurrentTicket}
            noData={data.data.length === 0}
          />
        )}
      </div>
    </div>
  );
};
export default TicketList;
