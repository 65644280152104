import React from 'react';
import './versions.scss';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getVersions } from '../../api/version';
import AddMajorVersion from './components/AddMajorVersion';
import AddMinorVersion from './components/AddMinorVersion';
import PublishVersion from './components/PublishVersion';
import UnpublishVersion from './components/UnpublishVersion';
import DeleteVersion from './components/DeleteVersion';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['versions'],
		queryFn: getVersions,
	});

	if (isPending) {
		return <CustomSkeleton count={20} />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const versions = data?.data || [];

	const renderActions = (rowData) => (
		<div className='flex space-x-2 justify-end'>
			<PublishVersion versionData={rowData} />
			<UnpublishVersion versionData={rowData} />
			<DeleteVersion versionData={rowData} />
		</div>
	);

	const renderVersion = ({ data }) => {
		return `${data.major}.${data.minor}`;
	};
	return (
		<React.Fragment>
			<div id='versions' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>versions</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 justify-end items-center'>
							<AddMajorVersion />
							<AddMinorVersion />
						</div>
					</div>
				</div>

				<div className='hidden lg:block'>
					{' '}
					<DataGrid
						showBorders={true}
						className='wide-card'
						dataSource={versions}
					>
						<Column
							caption='Version'
							dataType='string'
							cellRender={renderVersion}
						/>
						<Column
							dataField='status'
							caption='Status'
							dataType='boolean'
							cellRender={({ value }) => (value === '1' ? '✔️' : '❌')}
						/>
						<Column
							dataField='created_at'
							caption='Created At'
							dataType='date'
						/>
						<Column
							caption='Actions'
							width={200} // Set the column width to a small size
							alignment='right' // Right-align the actions column
							cellRender={({ data }) => renderActions(data)}
						/>

						<Paging defaultPageSize={15} />
						<Pager showPageSizeSelector={true} showInfo={true} />
					</DataGrid>
				</div>
				<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
					{versions.map((item, index) => {
						return (
							<div
								className={`flex  px-4 py-2 justify-between ${
									index % 2 === 1 && 'bg-[#e5e5e557]'
								} `}
								style={{ borderTop: '1px solid #e5e5e5' }}
							>
								<div className='flex flex-col'>
									<span>
										{item.status === '1' ? '✔️' : '❌'} {item.major}.
										{item.minor}
									</span>
									<span>
										{moment(item.created_at).format('DD/MM/YYYY')}
									</span>
								</div>
								<div>{renderActions(item)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};
