import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, LoadIndicator, Popup, Tooltip } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useState } from 'react';
import { deleteModule } from '../../../api/module';

const DeleteModule = (props) => {
	const { selectedModule, setSelectedModule } = props;
	const [isOpen, setIsOpen] = useState();
	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationKey: ['deleteModule' - selectedModule?.id],
		mutationFn: deleteModule,
		onSuccess: (res) => {
			notify(`Module deleted`, 'success', 2000);
			setSelectedModule(null);
			queryClient.invalidateQueries(['modules']);
			setIsOpen(false);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});

	return (
		<div>
			<Button
				id='delete'
				icon='trash'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
			/>
			<Tooltip
				target={`#delete`}
				showEvent='mouseenter'
				hideEvent='mouseleave'
				position='top'
			>
				Delete
			</Tooltip>
			{isOpen && (
				<Popup
					visible={isOpen}
					onHiding={() => setIsOpen(false)}
					dragEnabled={false}
					hideOnOutsideClick={true}
					showTitle={true}
					title='Delete '
					wrapperAttr={{
						class: 'knowledgepopup',
					}}
				>
					<div className='flex h-full flex-col justify-between'>
						<div className='text-xl'>
							Are you sure you want to Delete {selectedModule.title}?
						</div>
						<div className='flex justify-end   space-x-2'>
							<Button
								text='Cancel'
								type='danger'
								stylingMode='contained'
								disabled={isPending}
								onClick={() => setIsOpen(false)}
							/>
							<Button
								type='success'
								stylingMode='contained'
								disabled={isPending}
								onClick={() => {
									mutate([selectedModule.id]);
								}}
							>
								<span className='dx-button-text'>
									{isPending ? (
										<LoadIndicator
											width={'24px'}
											height={'24px'}
											visible={true}
										/>
									) : (
										'Delete'
									)}{' '}
								</span>
							</Button>
						</div>
					</div>
				</Popup>
			)}
		</div>
	);
};

export default DeleteModule;
