// to find a module by id even if it's nested deep
export const findModuleById = (modules, id) => {
  for (let module of modules) {
    if (module.id === id) return module;
    if (module.children) {
      const found = findModuleById(module.children, id);
      if (found) return found;
    }
  }
  return null;
};
