import React from 'react';
import './roles.scss';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getRoles } from '../../api/roles';
import CreateRole from './components/CreateRole';
import EditRole from './components/EditRole';
import DeleteRole from './components/DeleteRole';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['roles'],
		queryFn: getRoles,
	});

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const roles = data?.data || [];

	const renderActions = (rowData) => (
		<div className='flex space-x-2 justify-end'>
			<EditRole data={rowData} />
			<DeleteRole data={rowData} />
		</div>
	);

	return (
		<React.Fragment>
			<div id='roles' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Roles</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'>
							<CreateRole />
						</div>
					</div>
				</div>
				<div className='hidden lg:block'>
					<DataGrid
						showBorders={true}
						showColumnLines
						className='wide-card'
						dataSource={roles}
						allowColumnResizing={true}
					>
						<Column dataField='name' caption='Name' dataType='string' />
						<Column
							dataField='display_name'
							caption='Display name'
							dataType='string'
							minWidth={300}
						/>

						<Column
							dataField='caps'
							caption='Capabilities'
							dataType='string'
							cellRender={({ data }) => {
								return (
									<div className='flex space-x-2 flex-wrap'>
										{data.caps.map((cap, index) => {
											return (
												<span>
													{cap.name}
													{index + 1 !== data.caps.length && ', '}
												</span>
											);
										})}
									</div>
								);
							}}
						/>
						<Column
							caption='Actions'
							cellRender={({ data }) => renderActions(data)}
							width={200}
						/>

						<Paging defaultPageSize={15} />
						<Pager showPageSizeSelector={true} showInfo={true} />
					</DataGrid>
				</div>
				<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
					{roles.map((item, index) => {
						return (
							<div
								className={`flex  px-4 py-2 justify-between ${
									index % 2 === 1 && 'bg-[#e5e5e557]'
								} `}
								style={{ borderTop: '1px solid #e5e5e5' }}
							>
								<div className='flex flex-col'>
									<span>{item.display_name}</span>
									<div className='flex space-x-2 flex-wrap'>
										{item.caps.map((cap, indexx) => {
											return (
												<span>
													{cap.name}
													{indexx + 1 !== item.caps.length && ', '}
												</span>
											);
										})}
									</div>
									<span>
										{moment(item.created_at).format('DD/MM/YYYY')}
									</span>
								</div>
								<div>{renderActions(item)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};
