import React, { useEffect, useState } from "react";
import { getTicketsData } from "../../../api/module";
import { useQuery } from "@tanstack/react-query";
import { getTickets } from "../../../api/ticket";
import CustomSkeleton from "../../../components/loading/CustomSkeleton";

export default function TicketsStatistics() {
  const [selectedModuleId, setSelectedModuleId] = useState();
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["getTicketsData"],
    queryFn: getTicketsData,
  });

  // We use data.data[0].id as selected module ID
  // if no data return from tickets we use data.data[1] and so on

  // Fetching tickets for the selected module
  const { data: ticketsData } = useQuery({
    queryKey: ["tickets", selectedModuleId],
    queryFn: getTickets,
    variables: {
      module_id: selectedModuleId,
    },
    enabled: !!selectedModuleId, // Only run the query if selectedModuleId is available
  });

  useEffect(() => {
    setSelectedModuleId(data?.data[0]?.id);
  }, [data]);
  useEffect(() => {
    if (ticketsData?.data?.length !== 0) {
    } else {
      setSelectedModuleId(data?.data[1]?.id);
    }
  }, [ticketsData, data?.data]);

  if (isPending) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  // Calculate total open and closed tickets
  const totalOpenTickets = data.data.reduce(
    (sum, module) => sum + module.tickets_open,
    0
  );
  const totalClosedTickets = data.data.reduce(
    (sum, module) => sum + module.tickets_close,
    0
  );

  return (
    <div
      className="overflow-hidden rounded-lg bg-white   shadow sm:p-6"
      style={{ padding: "1rem", margin: "0.5rem" }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          <p
            className="truncate text-sm block font-medium text-gray-500"
            style={{ margin: 0, fontSize: "x-large" }}
          >
            {totalClosedTickets + totalOpenTickets}
          </p>
          <p style={{ margin: 0, fontSize: "xx-small" }}> Total</p>
        </div>

        <div style={{ display: "flex", marginLeft: "1rem" }}>
          <p
            className="truncate text-sm block font-medium text-gray-500"
            style={{ margin: 0, fontSize: "x-large" }}
          >
            {totalClosedTickets}
          </p>
          <p style={{ margin: 0, fontSize: "xx-small" }}> Closed</p>
        </div>

        <div style={{ display: "flex", marginLeft: "1rem" }}>
          <p
            className="truncate text-sm block font-medium text-gray-500"
            style={{ margin: 0, fontSize: "x-large" }}
          >
            {totalOpenTickets}
          </p>
          <p style={{ margin: 0, fontSize: "xx-small" }}> Open</p>
        </div>
      </div>

      <p className="truncate text-sm block text-gray-500" style={{ margin: 0 }}>
        Tickets
      </p>
    </div>
  );
}
