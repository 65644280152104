import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Popup, LoadIndicator } from 'devextreme-react';
import {
	Form,
	GroupItem,
	Label,
	Item,
	RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useCallback, useRef, useState } from 'react';
import List from 'devextreme-react/list';
import { getSubscribers } from '../../../api/subscriber';
import { getPageQuery } from '../../../api/knowledge';
import { createNewsletter } from '../../../api/newsletter';

const CreateNewsletter = (props) => {
	const [isOpen, setIsOpen] = useState(false); // Initialize isOpen state with false
	const formData = useRef({});
	const formRef = useRef(null); // Add a reference to the form
	const queryClient = useQueryClient(); // Get the query client

	const [selectedItemKeys, setSelectedItemKeys] = useState([]);

	const onSelectedItemKeysChange = useCallback(
		(item) => {
			setSelectedItemKeys(item);
		},
		[setSelectedItemKeys]
	);

	const { data: subscribersData } = useQuery({
		queryKey: ['subscribers'],
		queryFn: getSubscribers,
	});
	const { data: pagesData, isLoading: isPagesLoading } = useQuery({
		queryKey: [
			{
				parent_name: 'newsletter_parent',
			},
		],
		queryFn: getPageQuery,
	});

	const { mutate, isPending } = useMutation({
		mutationKey: ['createNewsletter'],
		mutationFn: createNewsletter,
		onSuccess: async (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				await queryClient.invalidateQueries(['newsletters']);
				notify(`newsletter created`, 'success', 2000);
				setIsOpen(false);
				setSelectedItemKeys([]);
				formRef.current.instance.resetValues(); // Reset form values
			}
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
			setIsOpen(false);
		},
	});

	const onSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		const result = formRef.current.instance.validate();
		if (result.isValid) {
			mutate({
				...formData.current,
				subscribers: selectedItemKeys,
			}); // Call mutate with formData
		}
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const renderListItem = (subscriber) => {
		return <div className='py-2'>{subscriber.email}</div>;
	};

	return (
		<div>
			<Button
				icon='plus'
				text='Create newsletter'
				stylingMode='outlined'
				className='filterbuttons'
				onClick={() => setIsOpen(true)}
			/>
			<Popup
				visible={isOpen}
				onHiding={handleClose}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Create Newsletter'
				wrapperAttr={{
					class: 'newsletter',
				}}
			>
				<div className='flex h-full flex-col justify-between'>
					<form className='ticket-form' onSubmit={onSubmit}>
						<Form ref={formRef} formData={formData.current}>
							<GroupItem colCount={2}>
								<Item
									dataField='name'
									editorOptions={{
										labelMode: 'floating',
									}}
									editorType='dxTextBox'
								>
									<RequiredRule message='Name is required' />
									<Label text='Name' visible={false} />
								</Item>

								<Item
									dataField='page_title'
									editorType='dxSelectBox'
									editorOptions={{
										dataSource: pagesData?.data,
										valueExpr: 'post_title',
										displayExpr: 'post_title',
										placeholder: 'Select a page',
										labelMode: 'floating',
										disabled: isPagesLoading,
									}}
								>
									<Label text='page_title' visible={false} />
								</Item>
							</GroupItem>
							<Item
								dataField='subscribers'
								editorOptions={{
									labelMode: 'floating',
								}}
								editorType='dxListBox'
							>
								<Label text='subscribers' visible={false} />

								<List
									elementAttr={{
										className: 'py-0',
									}}
									dataSource={subscribersData?.data}
									height={350}
									showSelectionControls={true}
									selectionMode={'all'}
									selectAllMode={'allPages'}
									selectedItemKeys={selectedItemKeys}
									selectByClick={true}
									onSelectedItemKeysChange={onSelectedItemKeysChange}
									itemRender={renderListItem}
									keyExpr='id'
								/>
							</Item>

							<GroupItem
								cssClass='flex space-x-2 justify-end'
								colCount={2}
							>
								<Button
									text='Cancel'
									type='danger'
									stylingMode='contained'
									className='mr-2'
									useSubmitBehavior={false}
									onClick={handleClose}
									disabled={isPending} // Add loading state to disable button
								/>
								<Button
									type='success'
									stylingMode='contained'
									text='Confirm'
									useSubmitBehavior={true}
									disabled={isPending} // Add loading state to disable button
								>
									<span className='dx-button-text'>
										{isPending ? (
											<LoadIndicator
												width='24px'
												height='24px'
												visible={true}
											/>
										) : (
											'Confirm'
										)}
									</span>
								</Button>
							</GroupItem>
						</Form>
					</form>
				</div>
			</Popup>
		</div>
	);
};

export default CreateNewsletter;
