import { Routes, Route, Navigate } from "react-router-dom";
import { adminRoutes, superUserRoutes, userRoutes } from "./app-routes";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import { useAuth } from "./contexts/auth";
// import { Footer } from "./components";

export default function Content() {
  const { user } = useAuth();

  // Utility function to get active routes based on user role
  const getActiveRoutes = (role) => {
    const routesConfig = [
      {
        isActive: role === "admin",
        routes: adminRoutes,
      },
      {
        isActive: role.toLowerCase().includes("admin") && role !== "admin",
        routes: superUserRoutes,
      },
      {
        isActive: !role.toLowerCase().includes("admin") && role !== "admin",
        routes: userRoutes,
      },
    ];

    return routesConfig
      .filter((routeObj) => routeObj.isActive)
      .flatMap((routeObj) => routeObj.routes);
  };

  // Get the active routes based on the user's role
  const activeRoutes = getActiveRoutes(user.role);

  return (
    <SideNavBarLayout title={window.env.APP_TITLE}>
      <Routes>
        {activeRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </SideNavBarLayout>
  );
}
