import moment from "moment";
import { Priority, Status } from "../constants/constants";
import { Button, Popup } from "devextreme-react";
import { useState } from "react";
import AddComment from "./AddComment";

const Ticket = ({ ticket, noData, setCurrentTicket }) => {
  const handleCopyLink = () => {
    const link = `${window.location.origin}/tickets?id=${ticket?.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const [image, setImage] = useState();
  if (!ticket || noData)
    return (
      <div className="flex items-center h-full justify-center ">
        <div className="flex flex-col items-center justify-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <span>No data to dsiplay</span>
        </div>
      </div>
    );

  return (
    <div className=" relative  ">
      <div className="p-4 sticky top-0 left-0 bg-gray-100 z-10 right-0">
        <div class="flex justify-between pb-4">
          <div>
            <div class="flex items-center justify-between gap-x-3">
              <span class="text-sm font-semibold leading-6  m-0  text-gray-900 w-full">
                {ticket.title}
              </span>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="truncate m-0 ">Ticket Id: {ticket.id}</p>
            </div>
            <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p class="truncate m-0 ">By: {ticket.issued_by}</p>
            </div>
            {ticket.cc && (
              <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p class="truncate m-0 whitespace-pre-line ">CC: {ticket.cc}</p>
              </div>
            )}
          </div>
          <div className="flex items-end  space-y-4 flex-col ">
            <div className="flex items-center  space-x-2 ">
              <Status status={ticket.status === "0" ? "Open" : "Closed"} />

              <Priority priority={ticket.priority} />
              <Button
                className="dx-button-small"
                type="normal"
                icon="share"
                onClick={() => handleCopyLink()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div dangerouslySetInnerHTML={{ __html: ticket.description }} />
        {ticket.files.length > 0 && (
          <>
            <div className="grid grid-cols-3 gap-2">
              {ticket.files.map((file) => {
                return (
                  <div
                    key={file.name}
                    className="hover:cursor-pointer"
                    onClick={() => {
                      setImage(file);
                    }}
                    id={file.name}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        width: "275px",
                      }}
                      // className=" object-cover h-48 w-48  "
                      src={file.url}
                      alt={file.name}
                    />
                  </div>
                );
              })}
            </div>
            <Popup
              width={800}
              height={700}
              showTitle={true}
              title={image?.name}
              dragEnabled={false}
              hideOnOutsideClick={true}
              visible={!!image}
              onHiding={() => {
                setImage();
              }}
              contentRender={() => (
                <img
                  style={{
                    width: "100%",
                  }}
                  src={image?.url}
                  alt={image?.name}
                />
              )}
              showCloseButton={true}
            />
          </>
        )}
        {ticket.comments.map((comment) => {
          const commentpastDate = moment(comment.created_at);
          const commenttimeFromNow = commentpastDate.fromNow();
          return (
            <div
              key={comment.title}
              className={`p-4 mb-4 `}
              style={{
                borderLeft: "4px solid #000",
                borderRight: "1px solid #e5e5e5",
                borderTop: "1px solid #e5e5e5",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              <div class="border flex justify-between ">
                <div>
                  <div class="flex items-center justify-between gap-x-3">
                    <span class="text-sm font-semibold leading-6  m-0  text-gray-900 w-full">
                      {comment.title}
                    </span>
                  </div>
                  <div class="mt-1 flex flex-col  text-xs leading-5 text-gray-500">
                    <p class="truncate m-0 ">Issued by {comment.issued_by}</p>
                    <p class="whitespace-nowrap m-0 ">
                      <time dateTime={commenttimeFromNow}>
                        {commenttimeFromNow}
                      </time>
                    </p>
                  </div>
                  {comment.cc && (
                    <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p class="truncate m-0 ">CC: {comment.cc}</p>
                    </div>
                  )}
                </div>
                <div className="flex items-end  space-y-4 flex-col ">
                  <div className="flex items-center  space-x-4 ">
                    <Status
                      status={comment.status === "0" ? "Open" : "Closed"}
                    />

                    {/* <Priority priority={comment.priority} /> */}
                  </div>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: comment.description,
                }}
              />
              {comment.files.length > 0 && (
                <>
                  <div className="grid grid-cols-3 gap-2">
                    {comment.files.map((file) => {
                      return (
                        <div
                          className="hover:cursor-pointer"
                          onClick={() => {
                            setImage(file);
                          }}
                          id={file.name}
                          key={file.name}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              width: "275px",
                            }}
                            // className=" object-cover h-48 w-48  "
                            src={file.url}
                            alt={file.name}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <Popup
                    width={800}
                    height={700}
                    showTitle={true}
                    title={image?.name}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={!!image}
                    onHiding={() => {
                      setImage();
                    }}
                    contentRender={() => (
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={image?.url}
                        alt={image?.name}
                      />
                    )}
                    showCloseButton={true}
                  />
                </>
              )}
            </div>
          );
        })}
        <div className="pt-4" style={{}}>
          {/* {!isSubmitComment && (
          <Button
            // width={120}
            text="Add a new comment"
            type="normal"
            stylingMode="outlined"
            onClick={() => setIsSubmitComment(isSubmitComment)}
          />
        )} */}
          <AddComment setCurrentTicket={setCurrentTicket} ticket={ticket} />
        </div>
      </div>
    </div>
  );
};

export default Ticket;
