import clientApi from './axiosconfig';

export async function getUsers() {
	const response = await clientApi.post('api/v1/users/getall', {});
	return response;
}

export async function editUser(user) {
	const response = await clientApi.post('api/v1/users/update', user);
	return response;
}

export async function createUser(user) {
	const response = await clientApi.post('api/v1/users/add', user);
	return response;
}

export async function deleteUser(e) {
	const response = await clientApi.post('api/v1/users/delete', e);
	return response;
}
