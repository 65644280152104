export const Priority = ({ priority }) => {
  const colors = {
    high: "text-orange-700 bg-orange-50 ring-orange-600/20",
    medium: "text-blue-700 bg-blue-50 ring-blue-600/20",
    low: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  };

  const priorityColor =
    colors[priority] || "text-gray-700 bg-gray-50 ring-gray-600/20";

  return (
    <p
      className={`rounded-md whitespace-nowrap m-0 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${priorityColor}`}
    >
      {priority}
    </p>
  );
};

export const Status = ({ status }) => {
  const colors = {
    Closed: "text-green-700 bg-green-50 ring-green-600/20",
    Open: "text-red-700 bg-red-50 ring-red-600/20",
  };

  const statusColor =
    colors[status] || "text-gray-700 bg-gray-50 ring-gray-600/20";

  return (
    <p
      className={`rounded-md whitespace-nowrap m-0 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${statusColor}`}
    >
      {status}
    </p>
  );
};
