import React, { useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";

import "./LoginForm.scss";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../api/auth";

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const formData = useRef({ email: "", password: "" });
  const { mutate, isPending } = useMutation({
    mutationKey: ["login"],
    mutationFn: login,
    onSuccess: (data) => {
      signIn(data);
      const { pathname, search, hash } = location.state?.from;
      const from = `${pathname}${search}${hash}` || "/tickets";
      navigate(from);
    },
    onError: (error) => {
      notify(error.response.data.code, "error", 2000);
    },
  });
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { username, password } = formData.current;
      mutate({ username, password });
    },
    [mutate]
  );

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={isPending}>
        <Item
          dataField={"username"}
          editorType={"dxTextBox"}
          editorOptions={usernameEditorOptions}
        >
          <RequiredRule message="Username is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"rememberMe"}
          editorType={"dxCheckBox"}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {isPending ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"link"}>
            <Link to={"/reset-password"}>Request License Id?</Link>
          </div>
        </Item>
        {/* <ButtonItem>
          <ButtonOptions
            text={"Create an account"}
            width={"100%"}
            onClick={onCreateAccountClick}
          />
        </ButtonItem> */}
      </Form>
    </form>
  );
}

const usernameEditorOptions = {
  stylingMode: "filled",
  placeholder: "Username",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "VCON License Id",
  mode: "password",
};
const rememberMeEditorOptions = {
  text: "Remember me",
  elementAttr: { class: "form-text" },
};
