import React, { useEffect, useState } from 'react';
import './knowledge.scss';
import { useQuery } from '@tanstack/react-query';
import { getModules } from '../../api/module';
import { TreeView } from 'devextreme-react';
import SingleKnowledge from './component/SingleKnowledge';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import CreateModule from './component/CreateModule';
import EditModule from './component/EditModule';
import DeleteModule from './component/DeleteModule';
import AddChildModule from './component/AddChildModule';
import { useLocation, useNavigate } from 'react-router-dom';
import { findModuleById } from './utils/findModuleById';
import useRoles from '../../utils/useRoles';
import { useScreenSize } from '../../utils/media-query';
import { Button } from 'devextreme-react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const [selectedModule, setSelectedModule] = useState(null);
	const [expandedItems, setExpandedItems] = useState([]);
	const navigate = useNavigate();
	const location = useLocation();
	const { isAdmin } = useRoles();
	const urlParams = new URLSearchParams(location.search);
	const moduleId = urlParams.get('id');
	const { isLarge, isMedium } = useScreenSize();

	const [isMobile, setIsMobile] = useState(false);

	const { isPending, isError, data, error } = useQuery({
		queryKey: ['modules'],
		queryFn: getModules,
	});

	useEffect(() => {
		const isMobile = isLarge === false && isMedium === false;
		if (isMobile) {
			if (moduleId) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		}
	}, [isLarge, moduleId, isMedium, setIsMobile]);

	useEffect(() => {
		if (data && data.data) {
			const selected = moduleId
				? findModuleById(data.data, moduleId)
				: data.data[0];
			setSelectedModule(selected);

			expandParents(data.data, selected);
		}
	}, [data, location.search]);

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const expandParents = (modules, module) => {
		const expand = (modules, moduleId) => {
			for (let module of modules) {
				if (module.children) {
					if (module.children.some((child) => child.id === moduleId)) {
						setExpandedItems((prev) => [...prev, module.id]);
						expand(modules, module.id);
					} else {
						expand(module.children, moduleId);
					}
				}
			}
		};
		expand(modules, module.id);
	};

	const buildTree = (nodes) => {
		return nodes.map((node) => ({
			id: node.id,
			text: node.title,
			title: module.title,
			expanded: expandedItems.includes(node.id), // Check expanded state
			selected: selectedModule?.id === node.id,
			items: node.children === false ? [] : buildTree(node.children),
		}));
	};

	const modifiedModules = data.data.map((module) => ({
		id: module.id,
		text: module.title,
		title: module.title,
		expanded: expandedItems.includes(module.id), // Check expanded state
		selected: selectedModule?.id === module.id,
		items:
			module.children === false || module?.children[0]?.id === undefined
				? []
				: buildTree(module.children),
	}));

	const onItemClick = (e) => {
		// Preserve the expanded state
		setSelectedModule(e.itemData);
		navigate(`?id=${e.itemData.id}`);
		if (!expandedItems.includes(e.itemData.id)) {
			setExpandedItems([...expandedItems, e.itemData.id]);
		}
	};

	return (
		<React.Fragment>
			<div id='knowledge' className={'content-block dx-card'}>
				<div
					style={{
						borderBottom: '1px solid #e5e5e5',
					}}
					className={'  grid grid-cols-8  '}
				>
					<div
						className='flex justify-between  col-span-3 lg:col-span-2'
						style={{
							borderRight: '1px solid #e5e5e5',
						}}
					>
						<span className='px-4 py-2 block bold '>
							<b>{selectedModule?.text || selectedModule?.title}</b>
						</span>
						{isAdmin && (
							<div className='hidden lg:flex '>
								<CreateModule />
								{selectedModule && (
									<>
										<AddChildModule selectedModule={selectedModule} />
										<EditModule selectedModule={selectedModule} />
										<DeleteModule
											setSelectedModule={setSelectedModule}
											selectedModule={selectedModule}
										/>
									</>
								)}
							</div>
						)}
					</div>
					<div className='col-span-5 justify-end flex items-center '>
						{isAdmin && (
							<div className='flex lg:hidden '>
								<CreateModule />
								{selectedModule && (
									<>
										<AddChildModule selectedModule={selectedModule} />
										<EditModule selectedModule={selectedModule} />
										<DeleteModule
											setSelectedModule={setSelectedModule}
											selectedModule={selectedModule}
										/>
									</>
								)}
							</div>
						)}
						{isMobile && (
							<>
								<Button
									className='filterbuttons mr-2'
									text='Back'
									type='normal'
									stylingMode='outlined'
									onClick={() => {
										setIsMobile(false);
										navigate('/knowledge');
									}}
								/>
							</>
						)}
					</div>
				</div>

				<div className={'  grid grid-cols-1 lg:grid-cols-8  '}>
					<div
						className={`treeViewContainer   lg:col-span-2  ${
							isMobile ? 'hidden' : ''
						}`}
					>
						<TreeView
							items={modifiedModules}
							selectionMode='single'
							id='treeView'
							searchEnabled={true}
							keyExpr='id'
							onItemClick={onItemClick}
							selectByClick={true}
							focusedRowEnabled={true}
							selectedItemKeys={[selectedModule?.id]} // Pass the selected module ID
						/>
					</div>
					<div
						id='singleKnowlege'
						className={`lg:col-span-6 lg:flex h-[83vh] overflow-auto ${
							isMobile ? '' : 'hidden'
						}`}
					>
						<SingleKnowledge selectedModule={selectedModule} />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
