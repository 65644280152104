import { useAuth } from "../contexts/auth";

const useRoles = () => {
  const { user } = useAuth();

  const isAdmin = user.role === "admin";
  const isSuperUser =
    user.role.toLowerCase().includes("admin") && user.role !== "admin";
  const isUser =
    !user.role.toLowerCase().includes("admin") && user.role !== "admin";

  return { isAdmin, isSuperUser, isUser };
};

export default useRoles;
