import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, LoadIndicator, Popup } from 'devextreme-react';
import { useState } from 'react';
import { publishVersion } from '../../../api/version';
import notify from 'devextreme/ui/notify';

const PublishVersion = (props) => {
	const { versionData } = props;
	const [isOpen, setIsOpen] = useState(false);
	const queryClient = useQueryClient(); // Get the query client

	const { mutate, isPending } = useMutation({
		mutationKey: ['publishVersion'],
		mutationFn: publishVersion,
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['versions']);
			notify('version published', 'success', 2000, {
				position: 'top-right',
			});
			setIsOpen(false);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});
	return (
		<div>
			<Button icon='upload' onClick={() => setIsOpen(true)} />

			<Popup
				visible={isOpen}
				onHiding={() => setIsOpen(false)}
				dragEnabled={false}
				hideOnOutsideClick={isPending ? false : true}
				showTitle={true}
				title='Publish version'
				wrapperAttr={{
					class: 'versionspopup',
				}}
			>
				<div className='flex h-full flex-col justify-between'>
					<div className='text-xl'>
						Are you sure you want to Publish this Version?
					</div>
					<div className='flex justify-end space-x-2'>
						<Button
							text='Cancel'
							loading
							type='danger'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => setIsOpen(false)}
						/>
						<Button
							type='success'
							stylingMode='contained'
							text='Confirm'
							disabled={isPending}
							onClick={() => {
								mutate(versionData);
							}}
						>
							{' '}
							<span className=''>
								{isPending ? (
									<LoadIndicator
										width={'24px'}
										height={'24px'}
										visible={true}
									/>
								) : (
									'publish'
								)}{' '}
							</span>
						</Button>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default PublishVersion;
