import { useQuery } from '@tanstack/react-query';
import { getPage } from '../../../api/knowledge';
import CustomSkeleton from '../../../components/loading/CustomSkeleton';
import ParsedHtml from './ParseHtml';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const SingleKnowledge = (props) => {
	const { selectedModule } = props;
	const location = useLocation();
	const { isPending, isError, data } = useQuery({
		queryKey: ['page', selectedModule?.title],
		queryFn: getPage,
		variables: {
			title: selectedModule?.title,
		},
		skip: !selectedModule,
	});

	useEffect(() => {
		const hash = location.hash.slice(1); // Remove the '#' from the hash
		if (data && data.data) {
			if (hash) {
				// Scroll to the element with the ID from the hash
				setTimeout(() => {
					const element = document.getElementById(hash);
					if (element) {
						element.scrollIntoView({ behavior: 'smooth' });
					}
				}, 500); // Adjust the timeout duration as needed
			}
		}
	}, [data, location.hash]);

	if (isError)
		return (
			<div className='flex items-center h-full justify-center '>
				<div className='flex flex-col items-center justify-center space-y-4'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z'
						/>
					</svg>

					<span>No data to dsiplay</span>
				</div>
			</div>
		);

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (!data?.data?.post_content)
		return (
			<div className='flex items-center h-full w-full justify-center '>
				<div className='flex flex-col items-center justify-center space-y-4'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z'
						/>
					</svg>

					<span>No data to dsiplay</span>
				</div>
			</div>
		);

	// const parseHTML = (html) => {

	//  data?.data?.post_content.split("<h2></h2>").map(c=>{
	//   return
	//  })
	//   };

	return (
		<div className='px-10'>
			{data?.data?.post_content && (
				<ParsedHtml html={data?.data?.post_content} />
			)}
		</div>
	);
};

export default SingleKnowledge;
