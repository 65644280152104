import axios from "axios";

// Create an axios instance for WordPress API
const clientApi = axios.create({
  baseURL:
    window.env.REACT_APP_ENDPOINT || `https://innovo-api.vcon.ae/wp-json/`,
});

// Add a request interceptor to dynamically insert the JWT token
clientApi.interceptors.request.use(
  function (config) {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? tokenData : "";
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  function (error) {
    // Handle request error here
    return Promise.reject(error);
  }
);

// Optionally add a response interceptor
clientApi.interceptors.response.use(
  function (response) {
    // Handle 2xx responses
    return response.data;
  },
  function (error) {
    // Handle non-2xx responses
    if (error?.response?.status === 401) {
      // Assuming 401 for Unauthorized access
      // Redirect user to login page or handle session timeout
      console.error("Session expired. Please log in again.");
    }
    return Promise.reject(error);
  }
);

export default clientApi;
