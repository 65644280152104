import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, LoadIndicator, Popup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useState } from 'react';
import { deactivateLicence } from '../../../api/licenses';

const DeactivateLiscences = (props) => {
	const { data } = props;
	const [isOpen, setIsOpen] = useState();
	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationKey: ['deactivatelicence' - data.id],
		mutationFn: deactivateLicence,
		onSuccess: (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				notify(`licence deactivated for ${data.email}`, 'success', 2000);

				queryClient.invalidateQueries(['licences']);
			}
			setIsOpen(false);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});

	return (
		<div>
			<Button
				icon='remove'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
			/>

			<Popup
				visible={isOpen}
				onHiding={() => setIsOpen(false)}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Dectivate Liscence'
				width={600}
				height={250}
			>
				<div className='flex h-full flex-col justify-between'>
					<div className='text-xl'>
						Are you sure you want to Deactivate {data.email} license?
					</div>
					<div className='flex justify-end   space-x-2'>
						<Button
							text='Cancel'
							type='danger'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => setIsOpen(false)}
						/>

						<Button
							type='success'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => {
								mutate({
									id: data.id,
									email: data.email,
								});
							}}
						>
							<span className='dx-button-text'>
								{isPending ? (
									<LoadIndicator
										width={'24px'}
										height={'24px'}
										visible={true}
									/>
								) : (
									'Confirm'
								)}{' '}
							</span>
						</Button>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default DeactivateLiscences;
