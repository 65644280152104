import {
  HomePage,
  TicketsPage,
  LiscencesPage,
  VersionsPage,
  KnowledgePage,
  UserlistPage,
  CapabilitiesPage,
  RolesPage,
  NewsletterPage,
  SubscriberPage,
  SettingsPage
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routesUser = [
  {
    path: "/tickets",
    element: TicketsPage,
  },
  {
    path: "/versions",
    element: VersionsPage,
  },
  {
    path: "/knowledge",
    element: KnowledgePage,
  },
];

const routesSupperUser = [
  ...routesUser,
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/licences",
    element: LiscencesPage,
  },
  {
    path: "/users",
    element: UserlistPage,
  },
];

const routesAdmin = [
  ...routesSupperUser,
  {
    path: "/capabilities",
    element: CapabilitiesPage,
  },
  {
    path: "/roles",
    element: RolesPage,
  },
  {
    path: "/newsletter",
    element: NewsletterPage,
  },
  {
    path: "/subscriber",
    element: SubscriberPage,
  }, 
  {
    path: '/settings',
    element: SettingsPage
  }
];

const getRoutes = (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      element: withNavigationWatcher(route.element, route.path),
    };
  });
};

export const adminRoutes = getRoutes(routesAdmin);
export const superUserRoutes = getRoutes(routesSupperUser);
export const userRoutes = getRoutes(routesUser);
