import React from 'react';
import './capabilities.scss';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import EditCapability from './components/EditCapability';
import DeleteCabapility from './components/DeleteCabapility';
import CreateCapability from './components/CreateCapability';
import { getCapabilities } from '../../api/capabilities';
import CustomSkeleton from '../../components/loading/CustomSkeleton';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['capabilities'],
		queryFn: getCapabilities,
	});

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const capabilities = data?.data || [];

	const renderActions = (rowData) => (
		<div className='flex space-x-2 justify-end'>
			<EditCapability data={rowData} />
			<DeleteCabapility data={rowData} />
		</div>
	);

	return (
		<React.Fragment>
			<div id='capabilities' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Capabilities</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'>
							<CreateCapability />
						</div>
					</div>
				</div>
				<div className='hidden lg:block'>
					{' '}
					<DataGrid
						showBorders={true}
						showColumnLines
						className='wide-card'
						dataSource={capabilities}
						allowColumnResizing={true}
					>
						<Column dataField='name' caption='Name' dataType='string' />
						<Column
							dataField='display_name'
							caption='Display name'
							dataType='string'
						/>

						<Column
							caption='Actions'
							cellRender={({ data }) => renderActions(data)}
							width={200}
						/>

						<Paging defaultPageSize={15} />
						<Pager showPageSizeSelector={true} showInfo={true} />
					</DataGrid>
				</div>
				<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
					{capabilities.map((item, index) => {
						return (
							<div
								className={`flex  px-4 py-2 justify-between ${
									index % 2 === 1 && 'bg-[#e5e5e557]'
								} `}
								style={{ borderTop: '1px solid #e5e5e5' }}
							>
								<div className='flex flex-col'>
									<span>{item.name}</span>
									<span>{item.display_name}</span>
								</div>
								<div>{renderActions(item)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};
