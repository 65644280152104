import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, LoadIndicator, Popup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useState } from 'react';
import { deleteRole } from '../../../api/roles';

const DeleteRole = (props) => {
	const { data } = props;
	const [isOpen, setIsOpen] = useState();
	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationKey: ['deleterole' - data.id],
		mutationFn: deleteRole,
		onSuccess: (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				notify(`role deleted`, 'success', 2000);

				queryClient.invalidateQueries(['roles']);
			}
			setIsOpen(false);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});

	return (
		<div>
			<Button
				icon='trash'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
			/>

			<Popup
				visible={isOpen}
				onHiding={() => setIsOpen(false)}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Delete Role'
				wrapperAttr={{
					class: 'rolespopupdelete',
				}}
			>
				<div className='flex h-full flex-col justify-between'>
					<div className='text-xl'>
						Are you sure you want to Delete {data.name}?
					</div>
					<div className='flex justify-end   space-x-2'>
						<Button
							text='Cancel'
							type='danger'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => setIsOpen(false)}
						/>
						<Button
							type='success'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => {
								mutate({
									ids: [data.id],
								});
							}}
						>
							<span className='dx-button-text'>
								{isPending ? (
									<LoadIndicator
										width={'24px'}
										height={'24px'}
										visible={true}
									/>
								) : (
									'Delete'
								)}{' '}
							</span>
						</Button>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default DeleteRole;
