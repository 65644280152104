import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { getUser } from "../api/auth";
import { useMutation } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({ loading: false });

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const signOut = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setUser(undefined);
    setLoading(false);
  }, []);

  const { mutate } = useMutation({
    mutationKey: ["tokenValidate"],
    mutationFn: getUser,
    onSuccess: (data) => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUser(userInfo);
      setLoading(false); // Ensure loading is set to false on success
    },
    onError: (error) => {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      setUser(undefined);
      setLoading(false); // Ensure loading is set to false on error
      notify(error.response.data.code, "error", 2000);
      navigate("/login");
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      mutate({});
    } else {
      setLoading(false); // Ensure loading is set to false if no token is present
    }
  }, [mutate]);

  const signIn = useCallback(async (userInfo) => {
    localStorage.setItem("token", userInfo.token);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    setUser(userInfo);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
