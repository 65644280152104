import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateAccountForm,
} from "./components";
import ConfirmMail from "./components/confirm-mail/ConfirmMail";
import PublicTicket from "./pages/tickets/components/PublicTicket";

export default function UnauthenticatedContent() {
  const location = useLocation();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SingleCard title={window.env.APP_TITLE + " Portal"}>
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path="/create-account"
        element={
          <SingleCard title="Sign Up">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to VCON's AUTOPrecast, and we will send you your License Id via Email."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path="/change-password/:recoveryCode"
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path="/confirm" element={<ConfirmMail />} />
      <Route path="/previewticket" element={<PublicTicket />} />
      <Route
        path="*"
        element={<Navigate to={"/login"} state={{ from: location }} />}
      ></Route>
    </Routes>
  );
}
