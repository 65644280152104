import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  Item,
  ButtonOptions,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import "devextreme-react/select-box";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
} from "devextreme-react/html-editor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTicket } from "../../../api/ticket";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react";
import { useAuth } from "../../../contexts/auth";
import CustomFileUploader from "./CustomFileUploader";

const AddComment = ({ ticket, setCurrentTicket }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient(); // Get the query client
  const [attachments, setAttachments] = useState([]);
  const [description, setDescription] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [formKey, setFormKey] = useState(0); // Key to force re-render
  const [status, setStatus] = useState("0"); // Default status
  const onStatusChange = (e) => {
    setStatus(e.addedItems[0].value);
  };
  const formRef = useRef(null); // Add a reference to the form

  const statusOptions = [
    { text: "Open", value: "0" },
    { text: "Closed", value: "1" },
    { text: "Canceled", value: "2" },
  ];

  const formData = useRef({
    cc: "",
    status: "0",
    files: null,
    module_id: ticket.module_id,
    type: "comment",
    parent_id: ticket.id,
    issued_by: user.user_email,
  });

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const { mutate, isPending } = useMutation({
    mutationKey: ["submitComment"],
    mutationFn: addTicket,
    onSuccess: (data) => {
      if (data.success === false) {
        notify(data.data, "error", 2000);
      } else {
        setCurrentTicket({
          ...ticket,
          comments: [...ticket.comments, data.data],
        });
        queryClient.invalidateQueries(["tickets", ticket.module_id]);
        notify("comment submitted", "sucess", 2000);

        resetForm(); // Reset the form after successful submission
      }
    },
    onError: (error) => {
      notify(error.response.data.code, "error", 2000);
    },
  });

  const resetForm = () => {
    formData.current = {
      cc: "",
      status: "0",
      files: null,
      module_id: ticket.module_id,
      type: "comment",
      parent_id: ticket.id,
      issued_by: user.user_email,
    };
    setAttachments([]);
    setDescription();
    setFormKey((prevKey) => prevKey + 1); // Update key to force re-render

    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // Prepare form data for submission
      const submitData = {
        ...formData.current,
        status: `${status}`,
        description,
        files: attachments,
      };
      mutate(submitData);
      // Here you can send `submitData` to your server
      // For example: await fetch('/api/tickets', { method: 'POST', body: submitData });
    },
    [attachments, mutate, description, status]
  );

  return (
    <div className=" h-full overflow-auto bg-white p-4">
      <div className="pb-1" style={{ borderBottom: "1px solid #e5e5e5" }}>
        <div className="flex items-start justify-between">
          <span className="text-base font-semibold leading-6 text-gray-900">
            Add Reply {ticket?.id}
          </span>
        </div>
      </div>
      <form ref={formRef} className={"ticket-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} key={formKey}>
          <GroupItem colCount={1}>
            <Item
              editorOptions={{
                labelMode: "floating",
              }}
              dataField={"cc"}
              editorType={"dxTextBox"}
            >
              <Label visible={false} />
            </Item>

            <SimpleItem
              dataField="status"
              editorType="dxButtonGroup"
              editorOptions={{
                items: statusOptions,
                selectedItemKeys: [status],
                keyExpr: "value",
                displayExpr: "text",
                onSelectionChanged: onStatusChange,
                elementAttr: { class: "status-button-group" },
              }}
              isRequired
            >
              <Label text="Status" visible={false} />
            </SimpleItem>
            <SimpleItem
              dataField="description"
              editorType="dxHtmlEditor"
              isRequired
            >
              <Label visible={false} />

              <HtmlEditor
                onFocusOut={({ event }) => {
                  setDescription(event.target.innerHTML);
                }}
                name="description"
                height={300}
              >
                <MediaResizing enabled={true} />
                <Toolbar>
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="underline" />
                  <Item name="strike" />
                  <Item name="header" acceptedValues={[2, 3, false]} />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="link" />
                  <Item name="image" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                </Toolbar>
              </HtmlEditor>
            </SimpleItem>
            <SimpleItem dataField="files" isRequired>
              <CustomFileUploader
                setAttachments={setAttachments}
                setIsUploading={setIsUploading}
              />
              <Label visible={false} />
            </SimpleItem>
            <ButtonItem>
              <ButtonOptions
                type={"default"}
                useSubmitBehavior={true}
                disabled={isUploading || isPending}
              >
                <span className="dx-button-text">
                  {isPending ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Send Reply"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};

export default AddComment;
