import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CustomSkeleton from './components/loading/CustomSkeleton';

const queryClient = new QueryClient({});

function App() {
	const { user, loading } = useAuth();

	if (loading) {
		return <CustomSkeleton />;
	}

	if (user) {
		return <Content />;
	}

	return <UnauthenticatedContent />;
}

export default function Root() {
	const screenSizeClass = useScreenSizeClass();

	return (
		<Router>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<NavigationProvider>
						<div className={`app ${screenSizeClass}`}>
							<App />
						</div>
					</NavigationProvider>
					{/* <ReactQueryDevtools initialIsOpen={false} /> */}
				</AuthProvider>
			</QueryClientProvider>
		</Router>
	);
}
