import React from "react";
import { getModules } from "../../../api/module";
import { useQuery } from "@tanstack/react-query";
import CustomSkeleton from "../../../components/loading/CustomSkeleton";

export default function KnowledgeBaseStatistics() {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["modules"],
    queryFn: getModules,
  });

  if (isPending) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div
      className="overflow-hidden rounded-lg bg-white   shadow sm:p-6"
      style={{ padding: "1rem", margin: "0.5rem" }}
    >
      <p
        className="truncate text-sm block font-medium text-gray-500"
        style={{ margin: 0, fontSize: "x-large" }}
      >
        {data.data.length}
      </p>

      <p className="truncate text-sm block text-gray-500" style={{ margin: 0 }}>
        Knowledge Base
      </p>
    </div>
  );
}
