import React from 'react';
import './liscences.scss';
import { getLiscences } from '../../api/licenses';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import ActivateLiscence from './components/ActivateLiscences';
import DeactivateLiscences from './components/DeactivateLiscences';
import DeleteLiscences from './components/DeleteLiscences';
import EditLiscence from './components/EditLiscence';
import CheckStatus from './components/CheckStatus';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['licences'],
		queryFn: getLiscences,
	});

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const licenses = data?.data[0] || [];

	const renderActions = (rowData) => (
		<div className='flex  justify-end'>
			<CheckStatus data={rowData} />
			<ActivateLiscence data={rowData} />
			<DeactivateLiscences data={rowData} />
			<EditLiscence data={rowData} />
			<DeleteLiscences data={rowData} />
		</div>
	);

	return (
		<React.Fragment>
			<div id='liscenses' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Licences</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'></div>
					</div>
				</div>
				<div className=''>
					<div className='hidden lg:block'>
						<DataGrid
							showBorders={true}
							showColumnLines
							className='wide-card'
							dataSource={licenses}
							allowColumnResizing={true}
							columnAutoWidth={true}
							searchPanel={true}
						>
							<Column
								dataField='email'
								caption='Email'
								dataType='string'
								allowFiltering={true}
								allowSearch={true}
							/>
							<Column
								dataField='machine_id'
								caption='Id'
								dataType='string'
							/>
							<Column
								dataField='created_at'
								caption='Created at'
								dataType='string'
							/>
							<Column
								dataField='expires_at'
								caption='Expires at'
								dataType='string'
							/>
							<Column
								dataField='valid_for'
								caption='Status'
								dataType='string'
								allowFiltering={false}
								cellRender={({ data }) => {
									const isActive =
										data.status === '1' || data.status === '99';
									const isConfirmed = data.user_confirmed === '1';
									const isMailSent = data.confirm_mail_sent === '1';
									return (
										<div>
											{data?.valid_for} | {isActive ? '✔️' : '❌'} |{' '}
											{isConfirmed ? '✔️' : '❌'} |{' '}
											{isMailSent ? '✔️' : '❌'}
										</div>
									);
								}}
							/>

							<Column
								caption='Actions'
								cellRender={({ data }) => renderActions(data)}
							/>

							<Paging defaultPageSize={15} />
							<Pager showPageSizeSelector={true} showInfo={true} />
						</DataGrid>
					</div>

					<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
						{licenses.map((item, index) => {
							const isActive =
								item.status === '1' || item.status === '99';
							const isConfirmed = item.user_confirmed === '1';
							const isMailSent = item.confirm_mail_sent === '1';
							return (
								<div
									className={`flex  px-4 py-2 justify-between ${
										index % 2 === 1 && 'bg-[#e5e5e557]'
									} `}
									style={{ borderTop: '1px solid #e5e5e5' }}
								>
									<div className='flex flex-col'>
										<span>{item.email}</span>
										<span>{item.machine_id}</span>
										<span>
											{moment(item.created_at).format('DD/MM/YYYY')}
										</span>
										<span>
											{moment(item.expires_at).format('DD/MM/YYYY')}
										</span>
										<div>
											{item?.valid_for} | {isActive ? '✔️' : '❌'} |{' '}
											{isConfirmed ? '✔️' : '❌'} |{' '}
											{isMailSent ? '✔️' : '❌'}
										</div>
									</div>
									<div>{renderActions(item)}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
