import React, { useCallback, useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import { Button } from "devextreme-react/button";
import { useMutation } from "@tanstack/react-query";
import { upload } from "../../../api/upload";

const CustomFileUploader = ({ setAttachments, setIsUploading }) => {
  const [previews, setPreviews] = useState([]); // State to manage previews
  const [uploadProgress, setUploadProgress] = useState([]); // State to manage upload progress

  const fileUploadMutation = useMutation({
    mutationKey: ["uploadFile"],
    mutationFn: ({ file, onUploadProgress }) => upload(file, onUploadProgress),
    onSuccess: (data, variables) => {
      if (data.success) {
        setAttachments((prev) => [...prev, data.data]);
        setUploadProgress((prev) =>
          prev.map((item) =>
            item.index === variables.index
              ? { ...item, progress: 100, complete: true }
              : item
          )
        );
      }
    },
  });

  const onFileChange = useCallback(
    async (e) => {
      setIsUploading(true);
      const newPreviews = [];
      const newProgress = [];
      let index = previews.length;

      for (const file of e.value) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = {
            url: event.target.result,
            name: file.name,
            size: file.size,
          };
          newPreviews.push(fileData);
          setPreviews((prev) => [...prev, fileData]);
        };
        reader.readAsDataURL(file);

        newProgress.push({ index, progress: 0, complete: false });
        // eslint-disable-next-line no-loop-func
        setUploadProgress((prev) => [
          ...prev,
          { index, progress: 0, complete: false },
        ]);

        await fileUploadMutation.mutateAsync({
          file,
          // eslint-disable-next-line no-loop-func
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const progress = Math.round((loaded * 100) / total);
            setUploadProgress((prev) =>
              prev.map((item) =>
                item.index === index ? { ...item, progress } : item
              )
            );
          },
          index,
        });
        index += 1;
      }
      setIsUploading(false);
    },
    [fileUploadMutation, previews, setIsUploading]
  );

  const removeFile = useCallback(
    (index) => {
      setAttachments((prev) => prev.filter((_, i) => i !== index));
      setPreviews((prev) => prev.filter((_, i) => i !== index));
      setUploadProgress((prev) => prev.filter((_, i) => i !== index));
    },
    [setAttachments, setPreviews, setUploadProgress]
  );

  return (
    <div>
      <FileUploader
        multiple={true}
        accept=".jpg,.jpeg,.png,.gif,.bmp,.mp4,.mov,.avi,.pdf,.doc,.docx"
        uploadMode="useForm"
        onValueChanged={onFileChange} // Handle file change
      />
      <div>
        {previews.map((preview, index) => (
          <div
            key={index}
            className="flex items-center justify-between mb-2 p-2 border border-gray-300 rounded"
          >
            <div className="w-12 h-12 flex items-center justify-center overflow-hidden border border-gray-200 rounded mr-2">
              <img
                src={preview.url}
                alt={`upload-preview-${index}`}
                className="max-h-full max-w-full object-cover"
              />
            </div>
            <div className="flex-1">
              <div className="font-bold">{preview.name}</div>
              <div className="text-sm text-gray-500">
                {(preview.size / 1024).toFixed(2)} KB
              </div>
              <div
                className={`w-full bg-gray-200 rounded-full h-2.5 mt-1 ${
                  uploadProgress.find((item) => item.index === index)?.complete
                    ? "hidden"
                    : ""
                }`}
              >
                <div
                  className={` h-2.5 rounded-full ${
                    uploadProgress.find((item) => item.index === index)
                      ?.complete
                      ? "bg-green-500"
                      : "bg-blue-600"
                  }`}
                  style={{
                    width: `${
                      uploadProgress.find((item) => item.index === index)
                        ?.progress || 0
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <Button
              icon="close"
              stylingMode="contained"
              type="danger"
              onClick={() => removeFile(index)}
              className="ml-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomFileUploader;
