import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getLiscences } from "../../../api/licenses";
import CustomSkeleton from "../../../components/loading/CustomSkeleton";

export default function LicencesStatistics() {
  const { isPending, isError, data, error } = useQuery({
    queryKey: ["licences"],
    queryFn: getLiscences,
  });

  if (isPending) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const licenses = data?.data[0] || [];
  return (
    <div
      className="overflow-hidden rounded-lg bg-white   shadow sm:p-6"
      style={{ padding: "1rem", margin: "0.5rem" }}
    >
      <p
        className="truncate text-sm block font-medium text-gray-500"
        style={{ margin: 0, fontSize: "x-large" }}
      >
        {licenses.length}
      </p>

      <p className="truncate text-sm block text-gray-500" style={{ margin: 0 }}>
        Licenses
      </p>
    </div>
  );
}
