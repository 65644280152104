import clientApi from './axiosconfig';

export async function getPage(e) {
	const title = e.queryKey[1];
	const response = await clientApi.post('api/v1/pages/get', { title });

	
	return response;
}

export async function getPageQuery(e) {
	const data = e.queryKey[0];
	const response = await clientApi.post('api/v1/pages/get', data);
	return response;
}
