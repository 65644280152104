import React, { useEffect, useState } from "react";
import "./tickets.scss";
import { Button } from "devextreme-react";
import TicketList from "./components/TicketsList";
import { useLocation, useNavigate } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [isNewTicket, setIsNewTicket] = useState(false);
  const [filterStatus, setFilterStatus] = useState();
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Extract the id from the query parameters
  const { isLarge, isMedium } = useScreenSize();
  const [isMobileTicket, setIsMobileTicket] = useState(false);

  useEffect(() => {
    const isMobile = isLarge === false && isMedium === false;
    if (isMobile) {
      if (id) {
        setIsMobileTicket(true);
      } else {
        setIsMobileTicket(false);
      }
    }
  }, [isLarge, id, isMedium, isMobileTicket]);

  const [dataStats, setDataStats] = useState({
    open: "0",
    closed: "0",
  });

  const isAll = filterStatus !== 0 && filterStatus !== 1;
  const isOpen = filterStatus === 0;
  const isClosed = filterStatus === 1;

  return (
    <React.Fragment>
      <div id="tickets" className={"content-block dx-card"}>
        <div
          style={{
            borderBottom: "1px solid #e5e5e5",
          }}
          className={"  grid grid-cols-6  "}
        >
          <div className="col-span-6">
            <div className="grid  lg:grid-cols-3">
              <div
                className="hidden lg:flex justify-end   w-full px-4 py-2 "
                style={{
                  borderRight: "1px solid #e5e5e5",
                }}
              >
                <div
                  className="flex  items-center justify-end overflow-hidden "
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Button
                    style={{
                      borderRight: "1px solid #c2c2c2",
                      backgroundColor: `${isAll ? "#d1d1d1" : "#fff"}`,
                    }}
                    type="normal"
                    className="listfilterbuttons"
                    text={`All - ${
                      parseInt(dataStats.open) + parseInt(dataStats.closed)
                    }`}
                    onClick={() => setFilterStatus()}
                  />

                  <Button
                    style={{
                      borderRight: "1px solid #c2c2c2",
                      backgroundColor: `${isOpen ? "#d1d1d1" : "#fff"}`,
                    }}
                    type="normal"
                    text={`Open - ${parseInt(dataStats.open)}`}
                    onClick={() => setFilterStatus(0)}
                    className="listfilterbuttons"
                  />
                  <Button
                    type="normal"
                    text={`Closed - ${parseInt(dataStats.closed)}`}
                    className="listfilterbuttons"
                    style={{
                      backgroundColor: `${isClosed ? "#d1d1d1" : "#fff"}`,
                    }}
                    onClick={() => setFilterStatus(1)}
                  />
                </div>
              </div>
              <div
                className={`col-span-2 flex items-center justify-between lg:justify-end px-4 py-2`}
              >
                {!isMobileTicket && (
                  <div
                    className="flex mr-4 lg:hidden items-center justify-end overflow-hidden "
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #e5e5e5",
                    }}
                  >
                    <Button
                      style={{
                        borderRight: "1px solid #c2c2c2",
                        backgroundColor: `${isAll ? "#d1d1d1" : "#fff"}`,
                      }}
                      type="normal"
                      className="listfilterbuttons"
                      text={`All - ${
                        parseInt(dataStats.open) + parseInt(dataStats.closed)
                      }`}
                      onClick={() => setFilterStatus()}
                    />

                    <Button
                      style={{
                        borderRight: "1px solid #c2c2c2",
                        backgroundColor: `${isOpen ? "#d1d1d1" : "#fff"}`,
                      }}
                      type="normal"
                      text={`Open - ${parseInt(dataStats.open)}`}
                      onClick={() => setFilterStatus(0)}
                      className="listfilterbuttons"
                    />
                    <Button
                      type="normal"
                      text={`Closed - ${parseInt(dataStats.closed)}`}
                      className="listfilterbuttons"
                      style={{
                        backgroundColor: `${isClosed ? "#d1d1d1" : "#fff"}`,
                      }}
                      onClick={() => setFilterStatus(1)}
                    />
                  </div>
                )}

                {isMobileTicket && (
                  <Button
                    className="filterbuttons mr-2"
                    text="Back"
                    type="normal"
                    stylingMode="outlined"
                    onClick={() => {
                      setIsMobileTicket(false);
                      navigate("/tickets");
                    }}
                  />
                )}
                <Button
                  className="filterbuttons"
                  text="Add a new ticket"
                  type="normal"
                  stylingMode="outlined"
                  onClick={() => {
                    navigate("/tickets?id=new");
                    setIsNewTicket(!isNewTicket);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div id="ticketslist" className="col-span-5">
            <TicketList
              filterStatus={filterStatus}
              isNewTicket={isNewTicket}
              setIsNewTicket={setIsNewTicket}
              setDataStats={setDataStats}
              isMobileTicket={isMobileTicket}
            />
          </div>
        </div>
      </div>{" "}
    </React.Fragment>
  );
};
