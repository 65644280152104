import { useQuery } from '@tanstack/react-query';
import { Button, LoadIndicator, Popup } from 'devextreme-react';
import { useState } from 'react';
import { getPublishResults } from '../../../api/newsletter';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import moment from 'moment';

const PublishResults = (props) => {
	const { data } = props;
	const [isOpen, setIsOpen] = useState(false);
	const {
		isPending,
		isError,
		data: publishResultsData,
		error,
	} = useQuery({
		queryKey: ['publishResultsData', data.id],
		queryFn: () =>
			getPublishResults({
				news_letter_id: data.id,
			}),
		enabled: isOpen,
	});
	return (
		<div>
			<Button
				icon='chart'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
			/>

			<Popup
				visible={isOpen}
				onHiding={() => setIsOpen(false)}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Newsletter publish status'
				wrapperAttr={{
					class: 'newsletterstats',
				}}
			>
				<div className='flex h-full flex-col justify-between'>
					<div className=''>
						{isPending && (
							<div className='flex justify-center items-center h-full'>
								<LoadIndicator
									width='50px'
									height='50px'
									visible={true}
								/>
							</div>
						)}
						{isError && (
							<div className='text-red-500'>
								{error.message ||
									'An error occurred while fetching data'}
							</div>
						)}

						{publishResultsData?.data && (
							<>
								<div className='hidden lg:block'>
									<DataGrid
										showBorders={true}
										showColumnLines
										className='wide-card'
										dataSource={publishResultsData.data}
										allowColumnResizing={true}
									>
										<Column
											dataField='subscriber_name'
											caption='To'
											dataType='string'
											minWidth={300}
										/>
										<Column
											dataField='result'
											caption='Status'
											dataType='string'
											cellRender={({ value }) =>
												value === '1' ? '✔️' : '❌'
											}
											width={75}
										/>

										<Column
											dataField='created_at'
											caption='Created at'
											dataType='string'
											minWidth={180}
										/>
										<Column
											dataField='message'
											caption='Message'
											dataType='string'
											minWidth={200}
										/>
										<Paging defaultPageSize={15} />
										<Pager
											showPageSizeSelector={true}
											showInfo={true}
										/>
									</DataGrid>
								</div>
								<div className='block lg:hidden'>
									{publishResultsData.data.map((item, index) => {
										return (
											<div
												className={`flex  px-4 py-2 justify-between ${
													index % 2 === 1 && 'bg-[#e5e5e557]'
												} `}
												style={{ borderTop: '1px solid #e5e5e5' }}
											>
												<div className='flex flex-col'>
													<span>
														{item.result === '1' ? '✔️' : '❌'}
														{' - '}
														{item.subscriber_name}
													</span>
													<span>{item.message}</span>
													<span>
														{moment(item.created_at).format(
															'DD/MM/YYYY'
														)}
													</span>
												</div>
											</div>
										);
									})}
								</div>
							</>
						)}
					</div>
					<div className='flex justify-end   space-x-2'>
						<Button
							text='Close'
							type='danger'
							stylingMode='contained'
							disabled={isPending}
							onClick={() => setIsOpen(false)}
						/>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default PublishResults;
