import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Popup, LoadIndicator, Tooltip } from 'devextreme-react';
import {
	Form,
	GroupItem,
	Label,
	Item,
	RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useRef, useState } from 'react';
import { editModule } from '../../../api/module';

const EditModule = (props) => {
	const { selectedModule } = props;
	const [isOpen, setIsOpen] = useState(false); // Initialize isOpen state with false
	const formData = useRef({});
	const formRef = useRef(null); // Add a reference to the form

	const queryClient = useQueryClient(); // Get the query client

	const { mutate, isPending } = useMutation({
		mutationKey: ['editModule'],
		mutationFn: editModule,
		onSuccess: async (res) => {
			await queryClient.invalidateQueries(['modules']);
			notify(`Module Updated`, 'success', 2000);
			setIsOpen(false);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
			setIsOpen(false);
		},
	});

	const onSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		mutate({ id: selectedModule.id, ...formData.current }); // Call mutate with formData
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<div>
			<Button
				id='editModule'
				icon='edit'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
				disabled={!selectedModule}
			/>
			<Tooltip
				target={`#editModule`}
				showEvent='mouseenter'
				hideEvent='mouseleave'
				position='top'
			>
				Edit
			</Tooltip>
			{isOpen && (
				<Popup
					visible={isOpen}
					onHiding={handleClose}
					dragEnabled={false}
					hideOnOutsideClick={true}
					showTitle={true}
					title={`Edit ${selectedModule.title} module`}
					wrapperAttr={{
						class: 'knowledgepopup',
					}}
				>
					<div className='flex h-full flex-col justify-between'>
						<form className='ticket-form' onSubmit={onSubmit}>
							<Form ref={formRef} formData={formData.current}>
								<GroupItem colCount={1}>
									<Item
										dataField='title'
										editorOptions={{
											labelMode: 'floating',
										}}
										editorType='dxTextBox'
									>
										<RequiredRule message='Title is required' />
										<Label text='Title' visible={false} />
									</Item>
								</GroupItem>
								<GroupItem
									cssClass='flex space-x-2 justify-end'
									colCount={2}
								>
									<Button
										text='Cancel'
										type='danger'
										stylingMode='contained'
										className='mr-2'
										useSubmitBehavior={false}
										onClick={handleClose}
										disabled={isPending} // Add loading state to disable button
									/>
									<Button
										type='success'
										stylingMode='contained'
										text='Confirm'
										useSubmitBehavior={true}
										disabled={isPending} // Add loading state to disable button
									>
										<span className='dx-button-text'>
											{isPending ? (
												<LoadIndicator
													width='24px'
													height='24px'
													visible={true}
												/>
											) : (
												'Confirm'
											)}
										</span>
									</Button>
								</GroupItem>
							</Form>
						</form>
					</div>
				</Popup>
			)}
		</div>
	);
};

export default EditModule;
