import React from "react";
import KnowledgeBaseStatistics from "./components/KnowledgeBaseStatistics";
import TicketsStatistics from "./components/TicketsStatistics";
import "./home.scss";
import LicencesStatistics from "./components/LicencesStatistics";
import VersionsStatistics from "./components/VersionsStatistics";

export default function Home() {
  return (
    <React.Fragment>
      <div className="p-4">
        <div  style={{"display":"block"}}>
          <TicketsStatistics />
          <KnowledgeBaseStatistics />

          <LicencesStatistics />
          <VersionsStatistics />
        </div>
      </div>
    </React.Fragment>
  );
}
