import clientApi from './axiosconfig';

export async function getNewsletters() {
	const response = await clientApi.post('api/v1/newsletter/get', {});
	return response;
}

export async function editNewsletter(user) {
	const response = await clientApi.post('api/v1/newsletter/update', user);
	return response;
}

export async function createNewsletter(user) {
	const response = await clientApi.post('api/v1/newsletter/add', user);
	return response;
}

export async function deleteNewsletter(e) {
	const response = await clientApi.post('api/v1/newsletter/delete', e);
	return response;
}

export async function publishNewsletter(e) {
	const response = await clientApi.post('api/v1/newsletter/publish', e);
	return response;
}
export async function getPublishResults(e) {
	const response = await clientApi.post('api/v1/newsletterarchive/get', e);
	return response;
}
