import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Popup, LoadIndicator } from 'devextreme-react';
import {
	Form,
	GroupItem,
	Label,
	Item,
	RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useRef, useState } from 'react';
import { createCapability } from '../../../api/capabilities';

const CreateCapability = (props) => {
	const [isOpen, setIsOpen] = useState(false); // Initialize isOpen state with false
	const formData = useRef({});
	const formRef = useRef(null); // Add a reference to the form

	const queryClient = useQueryClient(); // Get the query client

	const { mutate, isPending } = useMutation({
		mutationKey: ['createcapability'],
		mutationFn: createCapability,
		onSuccess: async (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				await queryClient.invalidateQueries(['capabilities']);
				notify(`Capability Created`, 'success', 2000);
				setIsOpen(false);
			}
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
			setIsOpen(false);
		},
	});

	const onSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		mutate(formData.current); // Call mutate with formData
	};

	const handleClose = () => {
		setIsOpen(false);
		formRef.current.instance.resetValues(); // Reset form values when popup is closed
	};

	return (
		<div>
			<Button
				icon='plus'
				text='Create Capability'
				className='filterbuttons'
				stylingMode='outlined'
				onClick={() => setIsOpen(true)}
			/>
			<Popup
				visible={isOpen}
				onHiding={handleClose}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Create capability'
				wrapperAttr={{
					class: 'cap',
				}}
			>
				<div className='flex h-full flex-col justify-between'>
					<form className='ticket-form' onSubmit={onSubmit}>
						<Form ref={formRef} formData={formData.current}>
							<GroupItem colCount={2}>
								<Item
									dataField='name'
									editorOptions={{
										labelMode: 'floating',
									}}
									editorType='dxTextBox'
								>
									<RequiredRule message='Name is required' />
									<Label text='Name' visible={false} />
								</Item>
								<Item
									dataField='display_name'
									editorOptions={{
										labelMode: 'floating',
									}}
									editorType='dxTextBox'
								>
									<RequiredRule message='Display name is required' />
									<Label text='Display Name' visible={false} />
								</Item>
							</GroupItem>
							<GroupItem
								cssClass='flex space-x-2 justify-end'
								colCount={2}
							>
								<Button
									text='Cancel'
									type='danger'
									stylingMode='contained'
									className='mr-2'
									useSubmitBehavior={false}
									onClick={handleClose}
									disabled={isPending} // Add loading state to disable button
								/>
								<Button
									type='success'
									stylingMode='contained'
									text='Confirm'
									useSubmitBehavior={true}
									disabled={isPending} // Add loading state to disable button
								>
									<span className='dx-button-text'>
										{isPending ? (
											<LoadIndicator
												width='24px'
												height='24px'
												visible={true}
											/>
										) : (
											'Confirm'
										)}
									</span>
								</Button>
							</GroupItem>
						</Form>
					</form>
				</div>
			</Popup>
		</div>
	);
};

export default CreateCapability;
