import { Button, Tooltip } from "devextreme-react";

const ParsedHtml = ({ html }) => {
  const handleCopyLink = (blockIndex) => {
    const link = `${window.location.origin}/knowledge${window.location.search}#${blockIndex}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div>
      {html
        ?.split(`<h2 class="wp-block-heading">break</h2>`)
        .map((e, index) => (
          <div className="relative pr-8">
            <div id={index} dangerouslySetInnerHTML={{ __html: e }} />
            <span id={`button-${index}`} className="absolute top-0 right-0 ">
              <Button
                type="normal"
                icon="share"
                onClick={() => handleCopyLink(index)}
              />
            </span>
            <Tooltip
              target={`#button-${index}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="top"
            >
              Share
            </Tooltip>
          </div>
        ))}
    </div>
  );
};

export default ParsedHtml;
