import React from 'react';
import './newsletter.scss';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getNewsletters } from '../../api/newsletter';

import EditNewsletter from './components/EditNewsletter';
import CreateNewsletter from './components/CreateNewsletter';
import DeleteNewsletter from './components/DeleteNewsletter';
import PublishNewsletter from './components/PublishNewsletter';
import PublishResults from './components/PublishResults';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['newsletters'],
		queryFn: getNewsletters,
	});

	if (isPending) {
		return <CustomSkeleton />;
	}

	if (isError) {
		return <span>Error: {error.message}</span>;
	}

	const newsletters = data?.data || [];
	const renderActions = (rowData) =>
		rowData ? (
			<div className='flex space-x-2 justify-end'>
				<EditNewsletter data={rowData} />
				<PublishNewsletter data={rowData} />
				<PublishResults data={rowData} />
				<DeleteNewsletter data={rowData} />
			</div>
		) : (
			<p>not found</p>
		);

	const dataGrid =
		data?.data?.length > 0 ? (
			<DataGrid
				showBorders={true}
				showColumnLines
				className='wide-card'
				dataSource={newsletters}
				allowColumnResizing={true}
			>
				<Column
					dataField='name'
					caption='Name'
					dataType='string'
					minWidth={300}
				/>
				<Column
					dataField='page_title'
					caption='Page title'
					dataType='string'
				/>
				<Column
					dataField='subscribers'
					caption='Subscribers'
					dataType='string'
					cellRender={({ data }) => data.subscribers.length}
				/>

				<Column
					caption='Actions'
					cellRender={({ data }) => renderActions(data)}
					width={200}
				/>

				<Paging defaultPageSize={15} />
				<Pager showPageSizeSelector={true} showInfo={true} />
			</DataGrid>
		) : (
			<p>not found</p>
		);

	return (
		<React.Fragment>
			<div id='newsletter' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Newsletters</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'>
							<CreateNewsletter />
						</div>
					</div>
				</div>

				<div className='hidden lg:block'> {dataGrid}</div>
				<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
					{newsletters.map((item, index) => {
						return (
							<div
								className={`flex  px-4 py-2 justify-between ${
									index % 2 === 1 && 'bg-[#e5e5e557]'
								} `}
								style={{ borderTop: '1px solid #e5e5e5' }}
							>
								<div className='flex flex-col'>
									<span>
										{item.name} {item.lastname}
									</span>
									<span>{item.page_title}</span>
									<span>{item.subscribers.length}</span>
									<span>
										{moment(item.created_at).format('DD/MM/YYYY')}
									</span>
								</div>
								<div>{renderActions(item)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};
