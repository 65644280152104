import React, { useState } from 'react';
import notify from 'devextreme/ui/notify';
import './ConfirmMail.scss';
import { useMutation } from '@tanstack/react-query';
import { Button, ScrollView } from 'devextreme-react';
import { confirmMail } from '../../api/mail';
import { useLocation } from 'react-router-dom';

export default function ConfirmMail() {
	const location = useLocation();

	const [message, setMessage] = useState();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const { mutate, isPending } = useMutation({
		mutationKey: ['mailConfirm'],
		mutationFn: confirmMail,
		onSuccess: (data) => {
			setMessage(data.data.message);
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});

	return (
		<ScrollView
			height={'100%'}
			width={'100%'}
			className={'with-footer single-card'}
		>
			<div className={'dx-card content'}>
				<div className={'header'}>
					<div className={'title'}>Confirm Email Address</div>

					<div className={' mt-4'}>{message}</div>
				</div>
				<Button
					icon={'email'}
					className='w-full'
					stylingMode='contained'
					type='success'
					text={isPending ? 'Verifying...' : 'Verify email'}
					disabled={isPending}
					onClick={() => {
						mutate({ token });
					}}
				/>
			</div>
		</ScrollView>
	);


}
