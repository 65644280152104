import clientApi from './axiosconfig';

export async function getSubscribers() {
	const response = await clientApi.post('api/v1/subscriber/get', {});
	return response;
}

export async function createSubscriber(user) {
	const response = await clientApi.post('api/v1/subscriber/add', user);
	return response;
}
export async function editSubscriber(user) {
	const response = await clientApi.post('api/v1/subscriber/update', user);
	return response;
}

export async function deleteSubscriber(e) {
	const response = await clientApi.post('api/v1/subscriber/delete', e);
	return response;
}
