import clientApi from "./axiosconfig";

export async function getVersions() {
  const response = await clientApi.post("api/v1/ver/get", {});
  return response;
}

export async function addMajorVersion() {
  const response = await clientApi.post("api/v1/ver/addmajor", {});
  return response;
}
export async function addMinorVersion() {
  const response = await clientApi.post("api/v1/ver/addminor", {});
  return response;
}

export async function publishVersion(e) {
  const response = await clientApi.post("api/v1/ver/publish", e);
  return response;
}

export async function unpublishVersion(e) {
  const response = await clientApi.post("api/v1/ver/unpublish", e);
  return response;
}

export async function deleteVersion(e) {
  const response = await clientApi.post("api/v1/ver/delete", e);
  return response;
}
