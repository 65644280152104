import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { validateLicence } from "../../../api/licenses";

const CheckStatus = (props) => {
  const { data } = props;
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ["validatelicence", data.machine_id || data.email],
    mutationFn: validateLicence,
    onSuccess: (res) => {
      if (res.success === false) {
        notify(res.data, "error", 2000);
      } else {
        notify(
          `licence status for ${data.email}: ${res?.data?.message}`,
          "success",
          2000
        );

        queryClient.invalidateQueries(["licences"]);
      }
    },
    onError: (error) => {
      notify(error.response.data.code, "error", 2000);
    },
  });

  return (
    <div>
      <Button
        icon="errorcircle"
        stylingMode="text"
        onClick={() => {
          mutate({ password: data.machine_id, username: data.email });
        }}
      />
    </div>
  );
};

export default CheckStatus;
