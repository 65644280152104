import clientApi from "./axiosconfig";

export async function getModules() {
  const response = await clientApi.post("api/v1/module/get", {});
  return response;
}

export async function createModule(module) {
  const response = await clientApi.post("api/v1/module/insertabove", module);
  return response;
}

export async function editModule(module) {
  const response = await clientApi.post("api/v1/module/update", module);
  return response;
}

export async function deleteModule(module) {
  const response = await clientApi.post("api/v1/module/delete", module);
  return response;
}

export async function addChildModule(module) {
  const response = await clientApi.post("api/v1/module/addchild", module);
  return response;
}

export async function getTicketsData() {
  const response = await clientApi.post("api/v1/module/getticketsdata", {});
  return response;
}
