import clientApi from './axiosconfig';

export async function getCapabilities(e) {
	const title = e.queryKey[1];
	const response = await clientApi.post('api/v1/caps/getall', { title });
	return response;
}

export async function editCapability(capability) {
	const response = await clientApi.post('api/v1/caps/update', capability);
	return response;
}

export async function createCapability(capability) {
	const response = await clientApi.post('api/v1/caps/add', capability);
	return response;
}

export async function deleteCapability(capability) {
	const response = await clientApi.post('api/v1/caps/delete', capability);
	return response;
}
