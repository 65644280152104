import clientApi from './axiosconfig';

export async function upload(file, onUploadProgress) {
	const data = new FormData();
	data.append('file', file);

	const response = await clientApi.post('api/v1/upload/file', data, {
		onUploadProgress,
	});
	return response;
}
