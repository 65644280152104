import clientApi from './axiosconfig';

export async function getRoles() {
	const response = await clientApi.post('api/v1/roles/getall', {});
	return response;
}

export async function editRole(user) {
	const response = await clientApi.post('api/v1/roles/update', user);
	return response;
}

export async function createRole(user) {
	const response = await clientApi.post('api/v1/roles/add', user);
	return response;
}

export async function deleteRole(e) {
	const response = await clientApi.post('api/v1/roles/delete', e);
	return response;
}
