import clientApi from './axiosconfig';

export async function getLiscences() {
	const response = await clientApi.post('api/v1/license/get', {});
	return response;
}

export async function editLicence(licence) {
	const response = await clientApi.post('api/v1/license/update', licence);
	return response;
}

export async function activateLicence(licence) {
	const response = await clientApi.post('api/v1/license/activate', licence);
	return response;
}

export async function validateLicence(licence) {
 
	const response = await clientApi.post('api/v1/license/validate', licence);
	return response;
}

export async function deactivateLicence(licence) {
	const response = await clientApi.post('api/v1/license/deactivate', licence);
	return response;
}

export async function deleteLicence(licence) {
	const response = await clientApi.post('api/v1/license/delete', licence);
	return response;
}
