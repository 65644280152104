import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CustomSkeleton = (props) => {
  const { height, count } = props;
  return (
    <SkeletonTheme height={height || 15}>
      <p>
        <Skeleton count={count || 3} />
      </p>
    </SkeletonTheme>
  );
};

export default CustomSkeleton;
