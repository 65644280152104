import React from 'react';
import './subscriber.scss';
import { useQuery } from '@tanstack/react-query';

import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getSubscribers } from '../../api/subscriber';
import CreateSubscriber from './components/CreateSubscriber';
import EditSubscriber from './components/EditSubscriber';
import DeleteSubscriber from './components/DeleteSubscriber';
import CustomSkeleton from '../../components/loading/CustomSkeleton';
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { isPending, isError, data, error } = useQuery({
		queryKey: ['subscribers'],
		queryFn: getSubscribers,
	});

	const dataSource = data?.data || [];

	const renderActions = (rowData) => (
		<div className='flex space-x-2 justify-end'>
			<EditSubscriber data={rowData} />
			<DeleteSubscriber data={rowData} />
		</div>
	);

	return (
		<React.Fragment>
			<div id='subscribers' className='content-block dx-card'>
				<div className='flex justify-between items-center py-2 px-4'>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center'>
							<span>Subscribers</span>
						</div>
					</div>
					<div>
						<div className='flex space-x-2 items-center'>
							<CreateSubscriber />
						</div>
					</div>
				</div>
				<div className=''>
					{isPending && (
						<div className='flex justify-center items-center h-full'>
							<CustomSkeleton count={15} />
						</div>
					)}
					{isError && (
						<div className='text-red-500'>
							{error.message || 'An error occurred while fetching data'}
						</div>
					)}
					{!isPending && dataSource && (
						<>
							<div className='hidden lg:block'>
								<DataGrid
									showBorders={true}
									showColumnLines
									className='wide-card'
									dataSource={dataSource}
									allowColumnResizing={true}
								>
									<Column
										dataField='name'
										caption='Name'
										dataType='string'
										cellRender={({ data }) => {
											return (
												<div>
													{data.firstname} {data.lastname}
												</div>
											);
										}}
									/>
									<Column
										dataField='email'
										caption='Email'
										dataType='string'
										minWidth={300}
									/>

									<Column
										dataField='created_at'
										caption='Created At'
										dataType='date'
										width={100}
									/>
									<Column
										caption='Actions'
										cellRender={({ data }) => renderActions(data)}
										width={200}
									/>

									<Paging defaultPageSize={15} />
									<Pager showPageSizeSelector={true} showInfo={true} />
								</DataGrid>
							</div>
							<div className='block lg:hidden  h-[85.5vh] overflow-auto'>
								{dataSource.map((item, index) => {
									return (
										<div
											className={`flex  px-4 py-2 justify-between ${
												index % 2 === 1 && 'bg-[#e5e5e557]'
											} `}
											style={{ borderTop: '1px solid #e5e5e5' }}
										>
											<div className='flex flex-col'>
												<span>
													{item.firstname} {item.lastname}
												</span>
												<span>{item.email}</span>
												<span>
													{moment(item.created_at).format(
														'DD/MM/YYYY'
													)}
												</span>
											</div>
											<div>{renderActions(item)}</div>
										</div>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};
