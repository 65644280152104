import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Popup, LoadIndicator } from 'devextreme-react';
import {
	Form,
	GroupItem,
	Label,
	Item,
	RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useRef, useState } from 'react';
import { editLicence } from '../../../api/licenses';

const EditLicence = (props) => {
	const { data } = props;
	const [isOpen, setIsOpen] = useState(false); // Initialize isOpen state with false
	const formData = useRef({ ...data });
	const queryClient = useQueryClient(); // Get the query client

	const { mutate, isPending } = useMutation({
		mutationKey: ['edirlicence' - data.id],
		mutationFn: editLicence,
		onSuccess: async (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				await queryClient.invalidateQueries(['licences']);
				notify(
					`liscence for ${data.email} validation setted for ${formData.current.valid_for} days`,
					'success',
					2000
				);
				setIsOpen(false);
			}
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
			setIsOpen(false);
		},
	});

	const onSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		mutate({
			data: { valid_for: formData.current.valid_for },
			id: formData.current.id,
		}); // Call mutate with formData
	};

	return (
		<div>
			<Button
				icon='edit'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
			/>

			<Popup
				visible={isOpen}
				onHiding={() => setIsOpen(false)}
				dragEnabled={false}
				hideOnOutsideClick={true}
				showTitle={true}
				title='Edit Licence'
				width={600}
				height={220}
			>
				<div className='flex h-full flex-col justify-between'>
					<form className='ticket-form' onSubmit={onSubmit}>
						<Form formData={formData.current}>
							<GroupItem colCount={1}>
								<Item
									dataField='valid_for'
									editorOptions={{
										labelMode: 'floating',
									}}
									editorType='dxNumberBox'
								>
									<RequiredRule message='Valid for is required' />
									<Label text='Valid for' visible={false} />
								</Item>
							</GroupItem>
							<GroupItem
								cssClass='flex space-x-2 justify-end'
								colCount={2}
							>
								<Button
									text='Cancel'
									type='danger'
									stylingMode='contained'
									className='mr-2'
									useSubmitBehavior={false}
									onClick={() => setIsOpen(false)}
									disabled={isPending} // Add loading state to disable button
								/>
								<Button
									type='success'
									stylingMode='contained'
									text='Confirm'
									useSubmitBehavior={true}
									disabled={isPending} // Add loading state to disable button
								>
									<span className='dx-button-text'>
										{isPending ? (
											<LoadIndicator
												width='24px'
												height='24px'
												visible={true}
											/>
										) : (
											'Confirm'
										)}{' '}
									</span>
								</Button>
							</GroupItem>
						</Form>
					</form>
				</div>
			</Popup>
		</div>
	);
};

export default EditLicence;
