import React from 'react';
import Form, { GroupItem, Item } from 'devextreme-react/form';
import './settings.scss';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSettings, updateSettings } from '../../api/settings';
import { getPageQuery } from '../../api/knowledge';
import { Button, LoadIndicator } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const queryClient = useQueryClient(); // Get the query client

	const settingObj = {
		trialCount: 'b',
		reminderBeforeExpire: 'c',
		sendingMailAddress: 'd',
		sendingMailPassword: 'e',
		STMPHost: 'f',
		STMPPort: 'g',
		STMPSecure: 'h',
		MailMessageSubject: 'i',
		CC: 'j',
		ticket_cc: '',
		mail_template_ticket: '',
		mail_template_ticket_description: '',
		mail_template_ticket_image: '',
		mailtemplatemailconfirm: '',
	};

	const settingsData = useQuery({
		queryKey: [settingObj],
		queryFn: getSettings,
	});

	const formData = settingsData?.data?.data;

	const { mutate, isPending } = useMutation({
		mutationKey: [formData],
		mutationFn: updateSettings,
		onSuccess: async (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				await queryClient.invalidateQueries(['newsletters']);
				notify(`Settings Saved`, 'success', 2000);
			}
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
		},
	});

	const pages_license_templates = useQuery({
		queryKey: [
			{
				parent_name: 'license_parent',
			},
		],
		queryFn: getPageQuery,
	});

	const pages_tickets_template = useQuery({
		queryKey: [
			{
				parent_name: 'tickets_parent',
			},
		],
		queryFn: getPageQuery,
	});

	return (
		<React.Fragment>
			<div className={'content-block'}>
				<div className={'dx-card responsive-paddings'}>
					<Form
						id='form'
						labelMode='aria-label'
						formData={formData}
						readOnly={false}
						showColonAfterLabel={false}
						labelLocation={'aria-label'}
						minColWidth={200}
						colCount={2}
					>
						<Item
							dataField='trialCount'
							editorOptions={{ disabled: false }}
						></Item>
						<Item
							dataField='reminderBeforeExpire'
							editorOptions={{ disabled: false }}
						></Item>
						<Item
							dataField='sendingMailAddress'
							editorOptions={{ disabled: false }}
						></Item>
						<Item dataField='STMPHost'></Item>
						<Item dataField='STMPPort'></Item>
						<Item dataField='STMPSecure'></Item>
						<Item dataField='MailMessageSubject'></Item>
						<Item dataField='CC'></Item>
						<Item
							dataField='ticket_cc'
							colSpan={2}
							editorType='dxTextArea'
						></Item>
						<Item
							dataField='mailtemplatemailconfirm'
							editorType='dxSelectBox'
							editorOptions={{
								items: Array.isArray(
									pages_license_templates?.data?.data
								)
									? pages_license_templates?.data?.data?.map(
											(p) => p.post_title
									  )
									: [],
								searchEnabled: true,
							}}
						></Item>
						<Item
							dataField='mail_template_ticket'
							editorType='dxSelectBox'
							editorOptions={{
								items: Array.isArray(pages_tickets_template?.data?.data)
									? pages_tickets_template?.data?.data?.map(
											(p) => p.post_title
									  )
									: [],
								searchEnabled: true,
							}}
						></Item>

						<Item
							dataField='mail_template_ticket_description'
							editorType='dxSelectBox'
							editorOptions={{
								items: Array.isArray(pages_tickets_template?.data?.data)
									? pages_tickets_template?.data?.data?.map(
											(p) => p.post_title
									  )
									: [],
								searchEnabled: true,
							}}
						></Item>

						<Item
							dataField='mail_template_ticket_image'
							editorType='dxSelectBox'
							editorOptions={{
								items: Array.isArray(pages_tickets_template?.data?.data)
									? pages_tickets_template?.data?.data?.map(
											(p) => p.post_title
									  )
									: [],
								searchEnabled: true,
							}}
						></Item>
						<GroupItem cssClass='flex space-x-2 justify-end' colSpan={2}>
							<Button
								type='success'
								stylingMode='contained'
								disabled={isPending}
								onClick={() => {
									mutate({
										...formData,
									});
								}}
							>
								<span className='dx-button-text'>
									{updateSettings?.isPending ? (
										<LoadIndicator
											width={'24px'}
											height={'24px'}
											visible={true}
										/>
									) : (
										'Save'
									)}
								</span>
							</Button>
						</GroupItem>
					</Form>
				</div>
			</div>
		</React.Fragment>
	);
};
