import React, { useCallback, useRef, useState } from "react";
import {
  Form,
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  Item,
  RequiredRule,
  ButtonOptions,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import "devextreme-react/select-box";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
} from "devextreme-react/html-editor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addTicket } from "../../../api/ticket";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react";
import { useAuth } from "../../../contexts/auth";
import CustomFileUploader from "./CustomFileUploader";
import { getModules } from "../../../api/module";

const SubmitTicketForm = ({
  setIsNewTicket,
  selectedModuleId,
  setCurrentTicket,
}) => {
  const { user } = useAuth();
  const queryClient = useQueryClient(); // Get the query client
  const [attachments, setAttachments] = useState([]);
  const [description, setDescription] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [priority, setPriority] = useState("high");

  const { data: modulesData, isLoading: isSModulesLoading } = useQuery({
    queryKey: ["modules"],
    queryFn: getModules,
  });

  const onPriorityChange = (e) => {
    setPriority(e.addedItems[0].value);
  };

  const formData = useRef({
    title: "",
    cc: "",
    priority: "medium",
    files: null,
    module_id: modulesData?.data[0].id,
    type: "ticket",
    status: "open",
    issued_by: user.user_email,
  });

  const priorityOptions = [
    { text: "High", value: "high" },
    { text: "Medium", value: "medium" },
    { text: "Low", value: "low" },
  ];

  const { mutate, isPending } = useMutation({
    mutationKey: ["submitticket"],
    mutationFn: addTicket,
    onSuccess: (data) => {
      if (data.success === false) {
        notify(data.data, "error", 2000);
      } else {
        setCurrentTicket(data.data);
        setIsNewTicket(false);
        queryClient.invalidateQueries(["tickets", selectedModuleId]);
      }
    },
    onError: (error) => {
      notify(error.response.data.code, "error", 2000);
    },
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // Prepare form data for submission
      const submitData = {
        ...formData.current,
        description,
        priority,
        files: attachments,
      };
      mutate(submitData);
    },
    [attachments, mutate, description, priority]
  );

  return (
    <div className=" h-full overflow-auto bg-white p-4">
      <div className="pb-1" style={{ borderBottom: "1px solid #e5e5e5" }}>
        <div className="flex items-start justify-between">
          <span className="text-base font-semibold leading-6 text-gray-900">
            New ticket
          </span>
          <div className="ml-3 flex h-7 items-center">
            <div
              className="hover:cursor-pointer"
              onClick={() => setIsNewTicket(false)}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <form className={"ticket-form"} onSubmit={onSubmit}>
        <Form formData={formData.current}>
          <GroupItem colCount={1}>
            <Item
              dataField={"title"}
              editorOptions={{
                labelMode: "floating",
              }}
              editorType={"dxTextBox"}
            >
              <RequiredRule message="title is required" />

              <Label visible={false} />
            </Item>
            <Item
              dataField={"cc"}
              editorOptions={{
                labelMode: "floating",
              }}
              editorType={"dxTextBox"}
            >
              <Label text="CC" visible={false} />
            </Item>
            <Item
              dataField="module_id"
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: modulesData?.data,
                valueExpr: "id",
                displayExpr: "title",
                placeholder: "Select a page",
                labelMode: "floating",
                disabled: isSModulesLoading,
              }}
            >
              <Label text="module" visible={false} />
            </Item>
            <SimpleItem
              dataField="priority"
              editorType="dxButtonGroup"
              editorOptions={{
                items: priorityOptions,
                selectedItemKeys: [priority],
                keyExpr: "value",
                displayExpr: "text",
                onSelectionChanged: onPriorityChange,
                elementAttr: { class: "priority-button-group" },
              }}
              isRequired
            >
              <Label text="priority" visible={false} />
            </SimpleItem>
            <SimpleItem
              dataField="description"
              editorType="dxHtmlEditor"
              isRequired
            >
              <HtmlEditor
                onFocusOut={({ event }) => {
                  setDescription(event.target.innerHTML);
                }}
                name="description"
                height={300}
              >
                <Label visible={false} />
                <MediaResizing enabled={true} />
                <Toolbar>
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="underline" />
                  <Item name="strike" />
                  <Item name="header" acceptedValues={[2, 3, false]} />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="link" />
                  <Item name="image" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                </Toolbar>
              </HtmlEditor>
              <Label text="Description" visible={false} />
            </SimpleItem>
            <SimpleItem dataField="files" isRequired>
              <CustomFileUploader
                setAttachments={setAttachments}
                setIsUploading={setIsUploading}
              />
              <Label text="Attachment" visible={false} />
            </SimpleItem>
            <ButtonItem>
              <ButtonOptions
                type={"default"}
                useSubmitBehavior={true}
                disabled={isUploading || isPending}
              >
                <span className="dx-button-text">
                  {isPending ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Add Ticket"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};

export default SubmitTicketForm;
