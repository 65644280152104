import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Popup, LoadIndicator, Tooltip } from 'devextreme-react';
import {
	Form,
	GroupItem,
	Label,
	Item,
	RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useCallback, useRef, useState } from 'react';
import List from 'devextreme-react/list';
import { getSubscribers } from '../../../api/subscriber';
import { editNewsletter } from '../../../api/newsletter';
import { getPage } from '../../../api/knowledge';

const EditNewsletter = (props) => {
	const { data } = props;
	const [isOpen, setIsOpen] = useState(false); // Initialize isOpen state with false
	const formData = useRef(data);
	const formRef = useRef(null); // Add a reference to the form
	const queryClient = useQueryClient(); // Get the query client

	const [selectedItemKeys, setSelectedItemKeys] = useState(
		data?.subscribers || []
	);

	const onSelectedItemKeysChange = useCallback(
		(item) => {
			setSelectedItemKeys(item);
		},
		[setSelectedItemKeys]
	);

	const { data: subscribersData, isLoading: isSubscribersLoading } = useQuery({
		queryKey: ['subscribers'],
		queryFn: getSubscribers,
	});
	const { data: pagesData, isLoading: isPagesLoading } = useQuery({
		queryKey: ['pages'],
		queryFn: getPage,
	});

	const { mutate, isPending } = useMutation({
		mutationKey: ['editNewsletter'],
		mutationFn: editNewsletter,
		onSuccess: async (res) => {
			if (res.success === false) {
				notify(res.data, 'error', 2000);
			} else {
				await queryClient.invalidateQueries(['newsletters']);
				notify(`newsletter updated`, 'success', 2000);
				setIsOpen(false);
				setSelectedItemKeys([]);
				formRef.current.instance.resetValues(); // Reset form values
			}
		},
		onError: (error) => {
			notify(error.response.data.code, 'error', 2000);
			setIsOpen(false);
		},
	});

	const onSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		const result = formRef.current.instance.validate();
		if (result.isValid) {
			mutate({
				...formData.current,
				subscribers: selectedItemKeys,
			}); // Call mutate with formData
		}
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const renderListItem = (subscriber) => {
		return <div className='py-2'>{subscriber.email}</div>;
	};

	const subscribersList = !isSubscribersLoading && subscribersData?.data && (
		<List
			elementAttr={{
				className: 'py-0',
			}}
			dataSource={subscribersData?.data}
			height={350}
			showSelectionControls={true}
			selectionMode={'all'}
			selectAllMode={'allPages'}
			selectedItemKeys={selectedItemKeys}
			selectByClick={true}
			onSelectedItemKeysChange={onSelectedItemKeysChange}
			itemRender={renderListItem}
			keyExpr='id'
		/>
	);
	return (
		<div>
			<Button
				icon='edit'
				stylingMode='text'
				onClick={() => setIsOpen(true)}
				elementAttr={{ id: `EditNewsletter-${data.id}` }}
			/>
			<Tooltip
				target={`EditNewsletter-${data.id}`}
				showEvent='mouseenter'
				hideEvent='mouseleave'
				position='top'
			>
				Edit
			</Tooltip>
			{isOpen && (
				<Popup
					visible={isOpen}
					onHiding={handleClose}
					dragEnabled={false}
					hideOnOutsideClick={true}
					showTitle={true}
					title='Edit Newsletter'
					wrapperAttr={{
						class: 'newsletter',
					}}
				>
					<div className='flex h-full flex-col justify-between'>
						<form className='newsletter-form' onSubmit={onSubmit}>
							<Form ref={formRef} formData={formData.current}>
								<GroupItem colCount={2}>
									<Item
										dataField='name'
										editorOptions={{
											labelMode: 'floating',
										}}
										editorType='dxTextBox'
									>
										<RequiredRule message='Name is required' />
										<Label text='Name' visible={false} />
									</Item>

									<Item
										dataField='page_title'
										editorType='dxSelectBox'
										editorOptions={{
											dataSource: pagesData?.data,
											valueExpr: 'post_title',
											displayExpr: 'post_title',
											placeholder: 'Select a page',
											labelMode: 'floating',
											disabled: isPagesLoading,
										}}
									>
										<Label text='page_title' visible={false} />
									</Item>
								</GroupItem>
								<Item
									dataField='subscribers'
									editorOptions={{
										labelMode: 'floating',
									}}
									editorType='dxListBox'
								>
									<Label text='subscribers' visible={false} />

									{subscribersList || <p>not found</p>}
								</Item>

								<GroupItem
									cssClass='flex space-x-2 justify-end'
									colCount={2}
								>
									<Button
										text='Cancel'
										type='danger'
										stylingMode='contained'
										className='mr-2'
										useSubmitBehavior={false}
										onClick={handleClose}
										disabled={isPending} // Add loading state to disable button
									/>
									<Button
										type='success'
										stylingMode='contained'
										text='Confirm'
										useSubmitBehavior={true}
										disabled={isPending} // Add loading state to disable button
									>
										<span className='dx-button-text'>
											{isPending ? (
												<LoadIndicator
													width='24px'
													height='24px'
													visible={true}
												/>
											) : (
												'Confirm'
											)}
										</span>
									</Button>
								</GroupItem>
							</Form>
						</form>
					</div>
				</Popup>
			)}
		</div>
	);
};

export default EditNewsletter;
