// import defaultUser from "../utils/default-user";
import clientApi from "./axiosconfig";

export async function login(userInfo) {
  const response = await clientApi.post("vcon/auth/v1/token", userInfo);
  return response;
}

export async function getUser() {
  const response = await clientApi.post("vcon/auth/v1/token/validate", {});
  return response;
  //   try {
  //     // Send request
  //     const response = await clientApi.post("vcon/auth/v1/token/validate", {});

  //     return {
  //       isOk: true,
  //       data: response.data,
  //     };
  //   } catch {
  //     return {
  //       isOk: false,
  //     };
  //   }
}

export async function createAccount(email, password) {
  try {
    // Send request

    return {
      isOk: true,
    };
    // eslint-disable-next-line no-unreachable
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request

    return {
      isOk: true,
    };
    // eslint-disable-next-line no-unreachable
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
