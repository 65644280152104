import clientApi from './axiosconfig';

export async function getTickets(e) {
	//   const status = e.queryKey[1];
	const response = await clientApi.post('api/v1/ticket/get', {});
	return response;
}

export async function getTicketsQuery(e) {
	const response = await clientApi.post('api/v1/ticket/get', e.queryKey);

	return response;
}

export async function addTicket(ticket) {
	const response = await clientApi.post('api/v1/ticket/add', ticket);
	return response;
}

export async function getPublicTicket(e) {
	const id = e.queryKey[1];

	const response = await clientApi.post('api/v1/ticket/getpreview', { id });

	return response;
}
