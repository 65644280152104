export const navigation = [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    roles: ["admin", "superUser"],
  },
  {
    text: "Tickets",
    path: "/tickets",
    icon: "ordersbox",
    roles: ["admin", "superUser", "user"],
  },
  {
    text: "Licences",
    path: "/licences",
    icon: "detailslayout",
    roles: ["admin", "superUser"],
  },
  {
    text: "Versions",
    path: "/versions",
    icon: "folder",
    roles: ["admin", "superUser", "user"],
  },
  {
    text: "Knowledge",
    path: "/knowledge",
    icon: "folder",
    roles: ["admin", "superUser", "user"],
  },

  {
    text: "Users",
    icon: "folder",
    roles: ["admin", "superUser"],
    items: [
      {
        text: "Users list",
        path: "/users",
        roles: ["admin", "superUser"],
      },
      {
        text: "Capabilities",
        path: "/capabilities",
        roles: ["admin"],
      },
      {
        text: "Roles",
        path: "/roles",
        roles: ["admin"],
      },
    ],
  },
  {
    text: "Newsletter",
    path: "/newsletter",
    icon: "folder",
    roles: ["admin"],
  },
  {
    text: "Subscriber",
    path: "/subscriber",
    icon: "folder",
    roles: ["admin"],
  },
  {
    text: "Settings",
    path: "/settings",
    icon: "contentlayout",
    roles: ["admin"],
  },
];
